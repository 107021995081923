import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, ButtonBlock, CloseIcon, colors, CustomInput, Label, Popup, PopupBody, PopupName, Row } from '../components';
import { Avatar } from 'sceyt-chat-react-uikit';
import { PlusOutlined } from '@ant-design/icons';
import { SceytContext } from '../sceytContext';
import axios from 'axios';

const SearchContainer = styled.div`
  position: relative;
  margin: 16px 0 24px 0;
`;

const SearchIcon = styled.div`
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: ${colors.textFootnote};
`;

const UserListContainer = styled.div`
  max-height: 35vh;
  overflow-y: auto;
`;

const UserListItem = styled.div`
  display: flex;
  align-items: center;
  padding: 12px;
  margin-bottom: 8px;
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    background-color: ${colors.gray1};
  }
`;

const UserInfo = styled.div`
  margin-left: 12px;
  flex-grow: 1;
`;

const UserName = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${colors.textColor1};
`;

const UserRole = styled.div`
  font-size: 12px;
  color: ${colors.textFootnote};
`;

const AddButton = styled.div`
  cursor: pointer;
  display: flex;
  height: 36px;
  width: 36px;
  margin-right: 4px;
  border-radius: 50%; 
  align-items: center;
  justify-content: center;
  border: 1px solid rgb(135, 134, 134);  
  transition: all 0.3s ease;   

  &:hover {
    background-color: rgba(104, 85, 246, 0.06);    
  }
`;

const AddChannelMembers = ({ userId, partnerAdminId, userRole }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  
  const sceytContext = useContext(SceytContext);
  const client = sceytContext.client;
  const activeChannel = sceytContext.activeChannel;

  useEffect(() => {
    if (isOpen) {
      fetchUsers();
    }
  }, [isOpen]);

  useEffect(() => {
    const filtered = users.filter(user => {
      const fullName = `${user.first_name} ${user.last_name}`.toLowerCase();
      return fullName.includes(searchQuery.toLowerCase()) ||
        user?.metadata?.role?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        user?.id?.toLowerCase().includes(searchQuery.toLowerCase());
    });
    setFilteredUsers(filtered);
  }, [searchQuery, users]);
  const fetchUsers = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/scyetChat/getAlluser`, 
        { withCredentials: true }
      );
      
      if (response.statusText === "OK") {
        let usersToSet = response.data.user.users;
        
        // Filter out current user and existing channel members
        usersToSet = usersToSet.filter(user => 
          user.id !== userId && 
          !activeChannel?.members?.some(member => member.id === user.id)
        );
  
        // Apply role-based filtering
        if (userRole === "partner") {
          usersToSet = usersToSet.filter(user => 
            user.metadata?.partnerAdminId === partnerAdminId || 
            user.id === partnerAdminId
          );
        } else if (userRole === "partnerAdmin") {
          usersToSet = usersToSet.filter(user =>
            user.metadata?.partnerAdminId === userId
          );
        }
  
       
        setUsers(usersToSet);
        setFilteredUsers(usersToSet);
      }
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const handleAddMembers = async () => {
    try {
      if (!activeChannel) {
        console.error('No active channel selected');
        return;
      }

      const payload = {
        user_id: userId,
        members: selectedMembers.map(memberId => ({
          id: memberId,
          role: "participant"
        }))
      };

      console.log("Adding members to channel:", activeChannel);
      console.log("Payload:", payload);

      const response = await axios.put(
        `${process.env.REACT_APP_SERVER_URL}/api/scyetChat/addmembers/${activeChannel.id}`,
        payload,
        { withCredentials: true }
      );

      if (response.data) {
        console.log("Successfully added members:", response.data);
        setIsOpen(false);
        setSelectedMembers([]);
        setSearchQuery('');
      }
    } catch (error) {
      console.error('Error adding members:', error);
    }
  };

  if (!client || (!activeChannel && isOpen)) {
    console.warn('No active channel or client');
    setIsOpen(false);
    return null;
  }

  return (
    <>
      <AddButton onClick={() => setIsOpen(true)}>
        <PlusOutlined style={{ fontSize: '20px' }} />
      </AddButton>

      {isOpen && (
        <div 
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 150
          }}
        >
          <div style={{ position: 'absolute' }}>
            <Popup
              backgroundColor={colors.white}
              maxHeight="600px"
              width="520px"
              maxWidth="520px"
              padding="0"
              style={{ position: 'relative', zIndex: 200 }}
            >
              <PopupBody paddingH="24px" paddingV="24px">
                <CloseIcon color={colors.textColor1} onClick={() => setIsOpen(false)} />
                <PopupName color={colors.textColor1} marginBottom="20px">
                  Add Channel Members
                </PopupName>

                <SearchContainer>
                  <SearchIcon>
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <circle cx="11" cy="11" r="8" />
                      <line x1="21" y1="21" x2="16.65" y2="16.65" />
                    </svg>
                  </SearchIcon>
                  <CustomInput
                    type="text"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    placeholder="Search users..."
                    color={colors.textColor1}
                    placeholderColor={colors.textFootnote}
                    backgroundColor={colors.gray1}
                    style={{ paddingLeft: '36px' }}
                  />
                </SearchContainer>

                <UserListContainer>
                  {filteredUsers.map(user => (
                    <UserListItem
                      key={user.id}
                      onClick={() => {
                        setSelectedMembers(prev =>
                          prev.includes(user.id)
                            ? prev.filter(id => id !== user.id)
                            : [...prev, user.id]
                        );
                      }}
                    >
                      <Avatar
                        image={user.avatar_url}
                        name={`${user.first_name} ${user.last_name}`}
                        size={35}
                        textSize={16}
                        setDefaultAvatar
                      />
                      <UserInfo>
                        <UserName>{`${user.first_name} ${user.last_name} (${user?.metadata?.role})`}</UserName>
                        <UserRole>{user?.metadata?.email}</UserRole>
                      </UserInfo>
                      <CustomInput
                        type="checkbox"
                        width="18px"
                        height="18px"
                        checked={selectedMembers.includes(user.id)}
                        readOnly
                      />
                    </UserListItem>
                  ))}
                </UserListContainer>

                <ButtonBlock style={{ marginTop: '24px' }}>
                  <Button
                    onClick={handleAddMembers}
                    backgroundColor={colors.primary}
                    color={colors.white}
                    disabled={selectedMembers.length === 0}
                  >
                    Add Members
                  </Button>
                </ButtonBlock>
              </PopupBody>
            </Popup>
          </div>
        </div>
      )}
    </>
  );
};

export default AddChannelMembers;