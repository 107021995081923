// utils/index.js
export const makeUsername = (user, getFirstNameOnly) => {
    return user
      ? user.firstName
        ? getFirstNameOnly
          ? `~${user.firstName.split(' ')[0]}`
          : `~${user.firstName.trim()} ${user.lastName.trim()}`.trim()
        : user.id || 'Deleted user'
      : 'Deleted user';
  };
  
  export const isJSON = (str) => {
    try {
      return JSON.parse(str) && !!str;
    } catch (e) {
      return false;
    }
  };
  
  export const calculateRenderedImageWidth = (width, height, maxWidth, maxHeight) => {
    const maxWdt = maxWidth || 420;
    const maxHg = maxHeight || 400;
    const minWidth = 165;
    const aspectRatio = width / height;
  
    if (aspectRatio >= maxWdt / maxHg) {
      return [
        Math.max(minWidth, Math.min(maxWdt, width)),
        Math.min(maxHg, height, maxWdt / aspectRatio) + 2
      ];
    } else {
      if (maxHg <= height) {
        return [Math.min(maxWdt, maxHg * aspectRatio), Math.min(maxHg, height)];
      } else {
        return [Math.min(maxWdt, height * aspectRatio), Math.min(maxHg, height)];
      }
    }
  };