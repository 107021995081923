import React, { useState, useContext, useEffect } from 'react';
import { DropDown } from "sceyt-chat-react-uikit";
import { Tooltip } from 'antd';
import { UserAddOutlined, UsergroupAddOutlined, MessageOutlined } from '@ant-design/icons';
import axios from 'axios';
import CustomCreateChannel from './CustomCreateChannel';
import newChannelButtonIcon from '../assets/svg/newChannelButtonIcon.svg';
import createGroupIcon from '../assets/svg/createGroup.svg';
import createChatIcon from '../assets/svg/createChat.svg';
import { Button, colors } from '../components';
import { SceytContext } from '../sceytContext';


export default function CreateChannelButton({ userRole, userId,partnerAdminId }) {
  const [showCreateChannel, setShowCreateChannel] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [channelType, setChannelType] = useState('');
  const [theme, setTheme] = useState('');

  const sceytContext = useContext(SceytContext);

  useEffect(() => {
    if (sceytContext && sceytContext.theme) {
      setTheme(sceytContext.theme);
    }
  }, [sceytContext]);

  const handleCreateChannel = (type) => {
    setChannelType(type);
    setShowCreateChannel(true);
    setShowDropdown(false);
  };

  const handleClick = () => {
    if (showCreateChannel) {
      setShowDropdown(false);
      setShowCreateChannel(false);
    } else {
      setShowDropdown(!showDropdown);
    }
  };

  const startChatWithPsm = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/scyetChat/startChatForPsm`, 
        { id: userId },
        { 
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      if (response.data) {
        console.log('Chat with PSM started:', response.data);
      }
    } catch (error) {
      console.error('Error starting chat with PSM:', error);
    }
  };

  if (userRole === 'partner' ||userRole==="partnerAdmin") {
    return (
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <div>
          <Tooltip title="Chat with PSM" placement="bottomLeft">
            <Button
              onClick={startChatWithPsm}
              style={{ marginLeft: "5px", display:"flex", justifyContent:"center", alignItems:"center", backgroundColor:"transparent",boxShadow: "none", border:"none",borderRadius:"50%" }}
            >
              <UserAddOutlined style={{ fontSize: "23px", color: colors.primary }} />
            </Button>
          </Tooltip>
        </div>
        <div>
          <Tooltip title="Create Channel" placement="top">
            <div className="relative">
              <DropDown
                forceClose={showCreateChannel || !showDropdown}
                position='center'
                trigger={
                  <div
                    className='custom_create_channel'
                    onClick={handleClick}
                    style={{ zIndex: 100 }}
                  >
                    <img src={newChannelButtonIcon} alt="new chat" />
                  </div>
                }
              >
                <ul className='custom_ul'>
                  <li
                    className={`custom_li messenger_create_channel_item ${theme}`}
                    onClick={() => handleCreateChannel('group')}
                  >
                    <img src={createGroupIcon} alt="create group" />
                    New group
                  </li>
                  <li
                    className={`custom_li messenger_create_channel_item ${theme}`}
                    onClick={() => handleCreateChannel('direct')}
                  >
                    <img src={createChatIcon} alt="create chat" />
                    New chat
                  </li>
                </ul>
              </DropDown>
              {showCreateChannel && (
                <CustomCreateChannel
                  type={channelType}
                  role={userRole}
                  userId={userId}
                  partnerAdminId={partnerAdminId}
                  onClose={() => {
                    setShowCreateChannel(false);
                    setShowDropdown(false);
                  }}
                />
              )}
            </div>
          </Tooltip>
        </div>
      </div>
    );
  }

  // For zyvkaUser role
  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
      <div>
        <Tooltip title="Send Bulk Message" placement="bottomLeft">
          <Button
            onClick={() => handleCreateChannel('bulkMessage')}
            style={{ marginLeft: "5px", display:"flex", justifyContent:"center", alignItems:"center", boxShadow: "none", border:"none" ,borderRadius:"50%",backgroundColor:"transparent"}}
          >
            <MessageOutlined style={{ fontSize: "23px", color: colors.primary }} />
          </Button>
        </Tooltip>
      </div>
      <div>
        <Tooltip title="Create Channel" placement="top">
          <div className="relative">
            <DropDown
              forceClose={showCreateChannel || !showDropdown}
              position='center'
              trigger={
                <div
                  className='custom_create_channel'
                  onClick={handleClick}
                  style={{ zIndex: 100 }}
                >
                  <img src={newChannelButtonIcon} alt="new chat" />
                </div>
              }
            >
              <ul className='custom_ul'>
                <li
                  className={`custom_li messenger_create_channel_item ${theme}`}
                  onClick={() => handleCreateChannel('group')}
                >
                  <img src={createGroupIcon} alt="create group" />
                  New group
                </li>
                <li
                  className={`custom_li messenger_create_channel_item ${theme}`}
                  onClick={() => handleCreateChannel('direct')}
                >
                  <img src={createChatIcon} alt="create chat" />
                  New chat
                </li>
              </ul>
            </DropDown>
            {showCreateChannel && (
              <CustomCreateChannel
                type={channelType}
                role={userRole}
                userId={userId}
                partnerAdminId={partnerAdminId}
                onClose={() => {
                  setShowCreateChannel(false);
                  setShowDropdown(false);
                }}
              />
            )}
          </div>
        </Tooltip>
      </div>
    </div>
  );
}