import React, { useState } from "react";
import {
  Button,
  Card,
  Divider,
  Descriptions,
  Col,
  Row,
  Drawer,
  Tag,
  Typography,
  message,
  Popconfirm,
} from "antd";

import { Link } from "react-router-dom";
import {
  DownloadOutlined,
  EditOutlined,
  RobotOutlined,
  ExportOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { UserState, selectUser } from "../features/userSlice";
import ReactTimeAgo from "react-time-ago";
import "../styles/customStyle.css";
import parse from "html-react-parser";
import { Modal, Dropdown, Form } from "semantic-ui-react";
import { IsZyvkaTeamMember } from "../utils/functions";
import {
  ArchiveJob,
  fetchAllPartnerAdmins,
  SendEmailToPartners,
  UnArchiveJob,
  updateClosedStatusJob,
  updateJob,
  updateJobJD,
  updateJobStatus,
} from "../api/ZyvkaMember";
import RichTextEditor from "../Small-Components/RichTextEditor";
import Currency, { ctcMap, CurrencySymbols } from "../Data/Currency";
import { ZyvkaMemberState } from "../features/ZyvkaMemberSlice";
import axios from "axios";
import { ClipLoader } from "react-spinners";
const API = axios.create({ baseURL: process.env.REACT_APP_SERVER_URL });
const { Title } = Typography;

const DescriptionItem = ({ title, content }) => (
  <div className="site-description-item-profile-wrapper">
    <p className="site-description-item-profile-p-label">{title}:</p>
    {content}
  </div>
);

const ViewJobDetails = ({
  data,
  width,
  ChangeFlag,
  skills,
  archived = false,
  closed = false,
  AllPartnerAdmins = [],
  AllZyvkaUsers = [],
}) => {
  const currencyOptions = [
    { id: 1, value: "INR", text: "INR" },
    { id: 2, value: "USD", text: "USD" },
  ];
  const modelOptions = [
    { id: 1, value: "Replacement", text: "Replacement" },
    { id: 2, value: "Refund", text: "Refund" },
  ];
  const revenueOptions = [
    { id: 1, value: "Percentage", text: "Percentage" },
    { id: 2, value: "Fixed Price", text: "Fixed Price" },
  ];

  const zyvkaState = useSelector(ZyvkaMemberState);
  const userState = useSelector(UserState);
  console.log(userState);
  const [emailModal, setEmailModal] = useState(false);
  const [isAddNewModalOpen, setIsAddNewModalOpen] = useState(false);
  const [fileObj, setFileObj] = useState();
  const [getNewJd, setnewJd] = useState("");
  const [allPartnerAdmins, setAllPartnerAdmins] = useState([]);
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const [messageForm, setMessageForm] = useState({
    body: "",
  });
  const [notiForm, setNotiForm] = useState({
    title: "",
    body: "",
  });
  const user = useSelector(selectUser);
  const [emailForm, setEmailForm] = useState({
    subject: "",
    body: "",
  });
  const [jdUpdateModal, setJdUpdateModal] = useState(false);
  const getValue = (value) => {
    setEmailForm({ ...emailForm, body: value });
  };
  const success = () => {
    message.success("notification sent successfully ");
  };
  const error = () => {
    message.error("some error occurred while sending the notification");
  };
  const info = () => {
    message?.info(
      "this job doesnt have any assigned partners, please add them first"
    );
  };
  const sendNotificationToIdsGroup = async (data) => {
    await API.post(`api/notification/sendIndividualNotification`, data, {
      withCredentials: true,
    });
  };
  const openJDUpdateModal = () => {
    setJdUpdateModal(true);
  };

  const [loading, setLoading] = useState(false);
  const handleshowPdf = async () => {
    try {
      if (data?.isZyvkaJdAiEnable || data?.jd_urn === "AiGenerated") {
        setLoading(true);
        const response = await axios.post(
          `${
            process.env.REACT_APP_SERVER_URL
          }/api/files/jd/generate/${data.job_title.split(" ").join("-")}`,
          {
            jobId: data?.id,
            name: user?.firstName + " " + user?.lastName,
            mobile: user?.mobileNum,
            email: user?.email,
          },
          {
            responseType: "blob",
            withCredentials: true,
          }
        );

        const url = URL.createObjectURL(response.data);
        window.open(url, "_blank");
        // }
        // else {
        //   console.error("Failed to generate PDF: ", response.statusText);
        // }
      } else {
        const url = `${process.env.REACT_APP_SERVER_URL}/api/files/jd/${data.jd_urn}`;
        window.open(url, "_blank");
      }
    } catch (error) {
      console.error("Error generating PDF: ", error);
    } finally {
      setLoading(false); // End loading indicator
    }
  };
  const handleshowPdfResume = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${
          process.env.REACT_APP_SERVER_URL
        }/api/files/resume/generate/${data.job_title.split(" ").join("-")}`,
        {
          params: {
            jobID: data?.id,
          },
          responseType: "blob",
          withCredentials: true,
        }
      );
      const url = URL.createObjectURL(response.data);
      window.open(url, "_blank");
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  const handleEmailSubmit = async () => {
    const isTitleValid = emailForm?.subject?.trim() !== "";
    const isBodyValid = emailForm?.body?.trim() !== "";
    if (!isBodyValid || !isTitleValid) {
      message.error("Please fill out all required fields");
    } else {
      const idArray = jobDetails?.assigned_partners?.filter((el) => el !== "");
      try {
        if (idArray?.length === 0) {
          info();
        } else {
          const res = await SendEmailToPartners({
            emailBody: emailForm?.body,
            partnerAdminIds: idArray,
            emailSubject: emailForm?.subject,
          });
          setEmailModal(false);
          message.success("Email sent successfully ");
        }
      } catch (e) {
        message.error("some error occurred while sending the emails");
        setEmailModal(false);
      }
    }
  };
  const handleSubmit = async () => {
    const isTitleValid = notiForm?.title?.trim() !== "";
    const isBodyValid = notiForm?.body?.trim() !== "";
    if (!isBodyValid || !isTitleValid) {
      message.error("Please fill out all required fields");
    } else {
      await sendNotificationToJobIds();
    }
  };
  const sendNotificationToJobIds = async () => {
    const idArray = jobDetails?.assigned_partners?.filter((el) => el !== "");

    try {
      if (idArray?.length === 0) {
        info();
      } else {
        await sendNotificationToIdsGroup({
          title: notiForm?.title,
          ids: idArray,
          body: notiForm?.body,
        });
        setIsAddNewModalOpen(false);
        success();
      }
    } catch (e) {
      error();
      setIsAddNewModalOpen(false);
    }
  };
  const handleCancelNotificationModal = () => {
    setIsAddNewModalOpen(false);
  };
  const handleOkNotificationModal = () => {
    setIsAddNewModalOpen(false);
  };
  const dispatch = useDispatch();

  const showDrawer = () => {
    setOpenDrawer(true);
  };
  const GetAllPartnerAdmins = async () => {
    try {
      await fetchAllPartnerAdmins(dispatch, setAllPartnerAdmins);
    } catch (error) {
      console.log(error);
    }
  };
  const onClose = () => {
    setOpenDrawer(false);
  };
  const partnerOptions = AllPartnerAdmins?.filter(
    (el) => !data?.pending_assignment.includes(el?.key)
  );

  const [opendrawer, setOpenDrawer] = useState(false);
  const [updateOpen, setUpdateOpen] = useState(false);

  const [jobDetails, setJobDetails] = useState({
    new_job_description: data?.job_description,
    jobID: data?.id,
    skills_req: data?.skills_req,
    min_CTC: data?.min_CTC,
    max_CTC: data?.max_CTC,
    min_experience_req: data?.min_experience_req,
    max_experience_req: data?.max_experience_req,
    currencyType: data?.currencyType,
    zyvka_team_members: data?.zyvka_team_members,
    assigned_partners: data?.assigned_partners,
    pending_assignment: data?.pending_assignment,
    status: data?.status,
    number_of_positions: data?.number_of_positions,
    internalTeamWorking: data?.internalTeamWorking,
    openToInternalTeamOnly: data?.openToInternalTeamOnly,
    isZyvkaAiEnable: data?.isZyvkaAiEnable,
    hotJob: data?.hotJob,
    job_title: data?.job_title,
    jdVideo_urn: data?.jdVideo_urn,
    invoicing_term:
      data?.positionRevenue?.[0]?.InvoicingTerms ||
      data?.client?.revenue?.[0]?.InvoicingTerms,
    payment_term:
      data?.positionRevenue?.[0]?.PaymentTerms ||
      data?.client?.revenue?.[0]?.PaymentTerms,
    replacement_model:
      data?.positionRevenue?.[0]?.ReplacementModel ||
      data?.client?.revenue?.[0]?.ReplacementModel,
    replacement_timeline:
      data?.positionRevenue?.[0]?.ReplacementTimeline ||
      data?.client?.revenue?.[0]?.ReplacementTimeline,
    revenue_model:
      data?.positionRevenue?.[0]?.RevenueMode ||
      data?.client?.revenue?.[0]?.RevenueMode,
    charge_value:
      data?.positionRevenue?.[0]?.Value || data?.client?.revenue?.[0]?.Value,
    currency:
      data?.positionRevenue?.[0]?.Currency ||
      data?.client?.revenue?.[0]?.Currency,
    jd_urn: data?.jd_urn,
  });
  const sendNotificationToIds = async (data) => {
    return await API.post(`api/notification/sendIndividualNotification`, data, {
      withCredentials: true,
    });
  };

  const updateJd = async () => {
    // console.log()
    try {
      if (data?.isZyvkaJdAiEnable || data?.jd_urn === "AiGenerated") {
        const res = await axios.post(
          `${process.env.REACT_APP_SERVER_URL}/api/zyvka/updateJDForJobText`,
          {
            jobID: data?.id || "",
            job_description: getNewJd,
          },
          { withCredentials: true }
        );
        if (res) {
          message.success("JD Updated Successfully ");
        }
      } else {
        const formdata = new FormData();
        formdata.append("oldFile", jobDetails?.jd_urn);
        formdata.append("file", fileObj);
        formdata.append("jobID", jobDetails?.jobID);
        if (fileObj) {
          await updateJobJD(formdata);
          message.success("JD Updated Successfully ");
        } else {
          message.error("please submit a JD to update ");
        }
      }
    } catch (error) {
      console.log(error);
    }
    setJdUpdateModal(false);
    setOpenDrawer(true);
    ChangeFlag();
  };
  const onSave = async (e) => {
    e.preventDefault();
    const zid = data?.zyvka_team_members;
    const newzid = jobDetails?.zyvka_team_members;
    const additions_zid = newzid.filter((item) => !zid.includes(item));
    const pid = data?.assigned_partners;
    const newpid = jobDetails?.assigned_partners;
    const additions_pid = newpid.filter((item) => !pid.includes(item));
    const combinedAdditions = [...additions_zid, ...additions_pid];

    if (combinedAdditions?.length > 0) {
      await sendNotificationToIds({
        title: `New Position Assigned: ${data?.company_name},${data?.job_title}`,
        ids: combinedAdditions,
        body: `Dear Team, we are delighted to inform you that you have been assigned a new position: ${data?.company_name}, ${data?.job_title}. Please view the deatils from Postions > Pending Assigned Positions tab to accept or decline the allocation.`,
      });
    }
    await updateJob(
      {
        new_job_description: jobDetails?.new_job_description,
        jobID: jobDetails?.jobID,
        skills_req: jobDetails?.skills_req,
        min_CTC: jobDetails?.min_CTC,
        max_CTC: jobDetails?.max_CTC,
        min_experience_req: jobDetails?.min_experience_req,
        max_experience_req: jobDetails?.max_experience_req,
        currencyType: jobDetails?.currencyType,
        zyvka_team_members: jobDetails?.zyvka_team_members,
        assigned_partners: jobDetails?.assigned_partners,
        pending_assignment: jobDetails?.pending_assignment,
        numberOfPositions: parseInt(jobDetails?.number_of_positions),
        internalTeamWorking: jobDetails?.internalTeamWorking,
        openToInternalTeamOnly: jobDetails?.openToInternalTeamOnly,
        isZyvkaAiEnable: jobDetails?.isZyvkaAiEnable,
        hotJob: jobDetails?.hotJob,
        job_title: jobDetails?.job_title,
        jdVideo_urn: jobDetails?.jdVideo_urn || "",
        revenue_model: jobDetails?.revenue_model,
        currency: jobDetails?.currency,
        charge_value: jobDetails?.charge_value,
        replacement_model: jobDetails?.replacement_model,
        replacement_timeline: jobDetails?.replacement_timeline,
        payment_term: jobDetails?.payment_term,
        invoicing_term: jobDetails?.invoicing_term,
      },
      dispatch
    );

    await updateClosedStatusJob({
      newStatus: jobDetails?.status,
      jobID: jobDetails?.jobID,
      numberOfPositions: parseInt(jobDetails?.number_of_positions),
    });

    setUpdateOpen(false);
    setOpenDrawer(true);
    ChangeFlag();
  };

  const sendMessageToUsers = async (messageData) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/scyetChat/sendChatToPartner`,
        messageData,
        {
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error in sendMessageToUsers:", error);
      throw error;
    }
  };

  const handleMessageSubmit = async () => {
    if (!messageForm.body.trim()) {
      message.error("Please enter a message");
      return;
    }
    setLoading(true);

    try {
      const idArray = jobDetails?.assigned_partners?.filter((el) => el !== "");
      if (idArray?.length === 0) {
        message.info(
          "This job doesn't have any assigned partners, please add them first"
        );
        return;
      }

      await sendMessageToUsers({
        body: messageForm.body,
        partners: idArray,
        userId: userState.userInfo.id,
      });

      setIsMessageModalOpen(false);
      message.success("Message sent successfully");
    } catch (error) {
      console.error("Error sending message:", error);
      message.error("Some error occurred while sending the message");
    } finally {
      setLoading(false);
    }
  };

  const JobStatusChange = async (status) => {
    try {
      if (window.confirm(`You Want to change Status to ${status}`)) {
        await updateJobStatus(
          {
            jobID: data.id,
            jobStatus: status,
          },
          dispatch
        );
        ChangeFlag();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const archiveJobHandler = async (id) => {
    if (
      window.confirm("Are you sure you want to archive this job ?") === true
    ) {
      await ArchiveJob(
        {
          id: id,
        },
        dispatch
      );
      ChangeFlag();
    }
  };
  const unarchiveJobHandler = async (id) => {
    if (
      window.confirm("Are you sure you want to unarchive this job ?") === true
    ) {
      await UnArchiveJob(
        {
          id: id,
        },
        dispatch
      );
      ChangeFlag();
    }
  };
  const status = (
    <Form.Select
      fluid
      style={{ cursor: "pointer", width: "120px" }}
      search
      options={[
        { key: 1, text: "Open", value: "open" },
        {
          key: 2,
          text: "Candidate offered",
          value: "candidate offered",
        },
        {
          key: 3,
          text: " Candidate accepted",
          value: "candidate accepted",
        },
        {
          key: 4,
          text: "Candidate joined",
          value: "candidate joined",
        },
        { key: 5, text: "On hold", value: "on hold" },
        { key: 6, text: "No more submissions", value: "no more submissions" },
        { key: 7, text: "Closed", value: "closed" },
        { key: 8, text: "Slot Pending", value: "Slot Pending" },
        { key: 9, text: "Requirement Changed", value: "Requirement Changed" },
      ]}
      defaultValue={data.status}
      onChange={(_e, data) => {
        JobStatusChange(data.value);
      }}
    />
  );
  return (
    <div>
      {loading && (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: "9999",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <ClipLoader loading={loading} size={50} color="#5d62b3" />
        </div>
      )}
      <br></br>
      <Card
        className="job-cards"
        hoverable
        bordered={false}
        title={data?.job_title}
        size="small"
        headStyle={{ fontSize: "18px", fontWeight: "bold" }}
        onClick={showDrawer}
        style={{ borderRadius: "10px", width: ` ${width}px` }}
        extra={
          userState?.role === "partner" ||
          userState?.role === "partnerAdmin" ? (
            <Button
              type="primary"
              style={
                data?.active_status === 4
                  ? { color: "red", border: "solid 1px white" }
                  : {}
              }
            >
              {data?.status}
            </Button>
          ) : (
            <Button
            style={
              data?.active_status === 4
                ? { background: "red", border: "solid 1px white" }
                : {}
            }
            type="primary">
              <Link
                to={`/job-details/${data?.id}`}
             
              >
                {data?.status}
              </Link>
            </Button>
          )
        }
      >
        <Descriptions
          title={data.company_name}
          extra={
            data?.openToInternalTeamOnly === true && (
              <Tag color="#6855f6">Internal Team Job </Tag>
            )
            // || (data?.isVideoResumeRequired === true  && <Tag color="#87d068">Internal Team Job </Tag>)
          }
        >
          <Descriptions.Item
            label="Location"
            contentStyle={{ display: "flex", justifyContent: "flex-end" }}
            span={3}
          >
            {data?.isRemote ? "Remote" : data.city + ", " + data.country}
          </Descriptions.Item>

          <Descriptions.Item
            label="Expected CTC"
            contentStyle={{ display: "flex", justifyContent: "flex-end" }}
            span={3}
          >
            {CurrencySymbols[data.currencyType]}{" "}
            {data.min_CTC + ctcMap[data.currencyType] + " - " + data.max_CTC}
            {`${ctcMap[data.currencyType]} P. A.`}
          </Descriptions.Item>

          <Descriptions.Item
            label="Positions Filled"
            contentStyle={{ display: "flex", justifyContent: "flex-end" }}
            span={3}
          >
            {data.current_filled_positions} / {data.number_of_positions}
          </Descriptions.Item>
          {userState?.role === "zyvkaUser" ||
          userState?.role === "zyvkaAdmin" ||
          userState?.role === "superAdmin" ? (
            <>
              <Descriptions.Item
                label="Total Applications"
                contentStyle={{ display: "flex", justifyContent: "flex-end" }}
                span={3}
              >
                {data?.applications?.length}
              </Descriptions.Item>

              <Descriptions.Item
                label="Opened"
                contentStyle={{ display: "flex", justifyContent: "flex-end" }}
                span={3}
              >
                <ReactTimeAgo
                  date={data.date}
                  locale="en-US"
                  timeStyle="facebook"
                />
              </Descriptions.Item>
            </>
          ) : (
            <Descriptions.Item
              label="Total Assigned Partners"
              contentStyle={{ display: "flex", justifyContent: "flex-end" }}
              span={3}
            >
                          { data?.assigned_partners?.length <= 2
                            ? 2
                            : data?.assigned_partners?.length >= 5
                            ? 5
                            : data?.assigned_partners?.length }
            </Descriptions.Item>
          )}
        </Descriptions>
        <br></br>
      </Card>

      <Drawer
        width={window.innerWidth > 740 ? 740 : window.innerWidth - 0}
        placement="right"
        onClose={onClose}
        visible={opendrawer}
        title="Job Details"
      >
        {/* hello there */}
        <Card
          title={data.job_title}
          headStyle={{ fontSize: "18px", fontWeight: "bold" }}
          extra={
            closed === false && IsZyvkaTeamMember(data, userState) ? (
              <>
                <div style={{ display: "flex" }}>
                  {data?.isZyvkaAiEnable && (
                    <div
                      style={{
                        marginTop: "0.7rem",
                        marginRight: "0.5rem",
                      }}
                    >
                      <RobotOutlined
                        style={{ fontSize: "1.8rem", color: "#8665f6" }}
                      />
                    </div>
                  )}
                  <div>
                    {updateOpen === false &&
                      (archived === false ? (
                        <Form.Select
                          fluid
                          style={{ cursor: "pointer", width: "120px" }}
                          search
                          options={[
                            { key: 1, text: "Open", value: "open" },
                            {
                              key: 2,
                              text: "Candidate offered",
                              value: "candidate offered",
                            },
                            {
                              key: 3,
                              text: " Candidate accepted",
                              value: "candidate accepted",
                            },
                            {
                              key: 4,
                              text: "Candidate joined",
                              value: "candidate joined",
                            },
                            { key: 5, text: "On hold", value: "on hold" },
                            {
                              key: 6,
                              text: "No more submissions",
                              value: "no more submissions",
                            },
                            { key: 7, text: "Closed", value: "closed" },
                            {
                              key: 8,
                              text: "Slot Pending",
                              value: "Slot Pending",
                            },
                            {
                              key: 9,
                              text: "Requirement Changed",
                              value: "Requirement Changed",
                            },
                          ]}
                          defaultValue={data.status}
                          onChange={(_e, data) => {
                            JobStatusChange(data.value);
                          }}
                        />
                      ) : (
                        <></>
                      ))}
                  </div>
                  <div>
                    {archived === false ? (
                      updateOpen ? (
                        <></>
                      ) : (
                        // <Button
                        //   type="primary"
                        //   style={{ marginLeft: "10px" }}
                        //   onClick={() => {
                        //     setUpdateOpen(true);
                        //   }}
                        // >
                        //   Update
                        // </Button>
                        <Button type="primary">
                          <Link
                            to={`/job-details/${data?.id}`}
                            style={
                              data?.active_status === 4
                                ? {
                                    background: "red",
                                    border: "solid 1px white",
                                  }
                                : {}
                            }
                          >
                            Update
                          </Link>
                        </Button>
                      )
                    ) : (
                      <>
                        <Button
                          style={{ color: "green", border: "solid 1px green" }}
                          onClick={() => unarchiveJobHandler(data?.id)}
                        >
                          Unarchive
                        </Button>
                      </>
                    )}
                  </div>
                </div>
              </>
            ) : (
              <>
                {IsZyvkaTeamMember(data, userState) ? (
                  <Button
                    style={
                      closed === false
                        ? { border: "solid 1px orange", color: "orange" }
                        : {
                            color: "white",
                            background: "red",
                          }
                    }
                    // onClick={() => setUpdateOpen(true)}
                  >
                    {data.status}
                  </Button>
                ) : (
                  <Button
                    style={
                      closed === false
                        ? { border: "solid 1px orange", color: "orange" }
                        : {
                            color: "white",
                            background: "red",
                          }
                    }
                  >
                    {data.status}
                  </Button>
                )}
              </>
            )
          }
        >
          <Row>
            <Col span={12}>
              <DescriptionItem
                title="Company Name"
                Link={"https://www.google.com"}
                content={
                  <a href={data?.client?.url} style={{ color: "inherit" }}>
                    {data?.company_name}
                  </a>
                }
              />
            </Col>
            <Col span={12}>
              <DescriptionItem
                title="Total Rounds"
                content={data.number_of_rounds}
              />
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <DescriptionItem
                title="Experience"
                content={
                  data.min_experience_req +
                  " - " +
                  data.max_experience_req +
                  " Years"
                }
              />
            </Col>
            <Col span={12}>
              <DescriptionItem
                title="CTC"
                content={`${CurrencySymbols[data.currencyType]}
            ${data.min_CTC}${
                  ctcMap[data.currencyType ? data.currencyType : "INR"]
                } - ${data.max_CTC}${
                  ctcMap[data.currencyType ? data.currencyType : "INR"]
                } P.A.`}
              />
            </Col>
          </Row>
          <Row>
            <Col span={24} style={{ paddingTop: "13px" }}>
              <DescriptionItem
                title="Skills Required"
                labelStyle
                content={
                  <div
                    style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}
                  >
                    {data.skills_req.map((data1) => {
                      return (
                        <>
                          <Tag color="blue">{data1.toUpperCase() + "  "}</Tag>
                        </>
                      );
                    })}
                  </div>
                }
              />
            </Col>
          </Row>
        </Card>
        {/* INTERNAL TEAM JOB / VIDEO RESUME REQUIRED CARD  */}
        {data?.openToInternalTeamOnly || data?.isVideoResumeRequired ? (
          <Card style={{ marginTop: "30px" }}>
            {data?.openToInternalTeamOnly && (
              <Tag color="#6855f6">Internal Team Job </Tag>
            )}
            {data?.isVideoResumeRequired && (
              <Tag color="#87d068">Video Resume Required </Tag>
            )}
          </Card>
        ) : (
          <></>
        )}

        <Card style={{ marginTop: "30px" }}>
          <Row>
            <Col span={24}>
              <DescriptionItem
                title={<b> Job Description</b>}
                content={
                  <>
                    {data?.job_description[0] === "<" ? (
                      <div>{parse(data?.job_description)}</div>
                    ) : (
                      <pre
                        style={{
                          color: "black",
                          whiteSpace: "pre-wrap",
                          fontFamily: "monospace",
                        }}
                      >
                        {data?.job_description}
                      </pre>
                    )}
                  </>
                }
              />
            </Col>
            <Divider />
            <Col span={6}>
              <a
                target="_blank"
                rel="noreferrer"
                // href={`${process.env.REACT_APP_SERVER_URL}/api/files/jd/${data.jd_urn}`}
              >
                <Button
                  type="primary"
                  icon={<DownloadOutlined />}
                  onClick={handleshowPdf}
                >
                  Download JD
                </Button>
              </a>
            </Col>
            {/* {
              data?.jdVideo_urn != "" &&
              <Col span={6}>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`${data?.jdVideo_urn}`}
                >
                  <Button type="primary" icon={<DownloadOutlined />}>
                  Watch JD explanation here:
                  </Button>
                </a>
              </Col>

            } */}
            {(data?.isZyvkaJdAiEnable || data?.jd_urn === "AiGenerated") && (
              <Col span={8}>
                <a
                  target="_blank"
                  rel="noreferrer"
                  // href={`${process.env.REACT_APP_SERVER_URL}/api/files/jd/${data.jd_urn}`}
                >
                  <Button
                    type="primary"
                    icon={<DownloadOutlined />}
                    onClick={handleshowPdfResume}
                  >
                    Download Ideal Resume
                  </Button>
                </a>
              </Col>
            )}
            {/* <Col span={6}>
              <a
                target="_blank"
                rel="noreferrer"
              // href={`${process.env.REACT_APP_SERVER_URL}/api/files/jd/${data.jd_urn}`}
              >
                <Button type="primary" icon={<DownloadOutlined />} onClick={handleshowPdfResume}>
                  Download resume
                </Button>
              </a>
            </Col> */}
            {(userState?.role === "zyvkaUser" ||
              userState?.role === "zyvkaAdmin" ||
              userState?.role === "superAdmin") && (
              <Col span={6}>
                <Button
                  type="primary"
                  icon={<EditOutlined />}
                  onClick={openJDUpdateModal}
                >
                  Update JD
                </Button>
              </Col>
            )}
          </Row>
        </Card>
        {data?.jdVideo_urn && (
          <Card>
            <DescriptionItem
              title={"Watch JD explanation here"}
              content={
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`${data?.jdVideo_urn}`}
                >
                  Jd Video Link &nbsp;
                  <ExportOutlined />
                </a>
              }
            ></DescriptionItem>
          </Card>
        )}
        <Card style={{ marginTop: "30px" }}>
          <Row>
            <Col span={24}>
              <DescriptionItem
                title={<b>Hiring Process / Round Details</b>}
                content={data.hiring_process.map((val, _idx) => {
                  //console.log(val);
                  return (
                    <div>
                      <Title level={5}>{JSON.parse(val).name} :</Title>
                      <pre
                        style={{
                          whiteSpace: "pre-wrap",
                          fontFamily: "inherit",
                        }}
                      >
                        {JSON.parse(val).description}
                      </pre>
                    </div>
                  );
                })}
              />
            </Col>
          </Row>
        </Card>

        {(userState?.role === "zyvkaUser" ||
          userState?.role === "zyvkaAdmin" ||
          userState?.role === "superAdmin") && (
          <>
            {" "}
            <Card style={{ marginTop: "30px" }}>
              <Title level={4}>Client Contacts</Title>
              <Row justify="center">
                <Col span={12}>
                  <DescriptionItem
                    title="Email"
                    content={data?.job_incharge?.email}
                  />
                </Col>
                <Col span={12}>
                  <DescriptionItem
                    title="Phone Number"
                    content={`${data?.job_incharge?.country_code} ${data?.job_incharge?.mobileNum}`}
                  />
                </Col>
              </Row>
            </Card>
          </>
        )}
        {data?.positionRevenue?.length > 0 ? (
          <Card style={{ marginTop: "30px" }}>
            <Title level={4}>Revenue Details</Title>
            <Row>
              <Col span={12}>
                <DescriptionItem
                  title="Revenue Model"
                  content={data?.positionRevenue[0]?.RevenueMode}
                />
              </Col>
              <Col span={6}>
                <DescriptionItem
                  title="Charge Value"
                  content={`${data?.positionRevenue[0]?.Value}`}
                />
              </Col>
              <Col span={6}>
                <DescriptionItem
                  title="Currency"
                  content={`${data?.positionRevenue[0]?.Currency}`}
                />
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <DescriptionItem
                  title="Payment Terms"
                  content={data?.positionRevenue[0]?.PaymentTerms + " days"}
                />
              </Col>
              <Col span={12}>
                <DescriptionItem
                  title="Invoicing Terms"
                  content={`${data?.positionRevenue[0]?.InvoicingTerms} days`}
                />
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <DescriptionItem
                  title="Replacement Timeline"
                  content={
                    data?.positionRevenue[0]?.ReplacementTimeline + " days"
                  }
                />
              </Col>
              <Col span={12}>
                <DescriptionItem
                  title="Replacement Model"
                  content={`${data?.positionRevenue[0]?.ReplacementModel}`}
                />
              </Col>
            </Row>
          </Card>
        ) : data?.client?.revenue?.length > 0 ? (
          <Card style={{ marginTop: "30px" }}>
            <Title level={4}>Revenue Details</Title>
            <Row>
              <Col span={12}>
                <DescriptionItem
                  title="Revenue Model"
                  content={data?.client?.revenue?.[0]?.RevenueMode}
                />
              </Col>
              <Col span={6}>
                <DescriptionItem
                  title="Charge Value"
                  content={`${data?.client?.revenue?.[0]?.Value}`}
                />
              </Col>
              <Col span={6}>
                <DescriptionItem
                  title="Currency"
                  content={`${data?.client?.revenue?.[0]?.Currency}`}
                />
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <DescriptionItem
                  title="Payment Terms"
                  content={`${data?.client?.revenue?.[0]?.PaymentTerms} days`}
                />
              </Col>
              <Col span={12}>
                <DescriptionItem
                  title="Invoicing Terms"
                  content={`${data?.client?.revenue?.[0]?.InvoicingTerms} days`}
                />
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <DescriptionItem
                  title="Replacement Timeline"
                  content={`${data?.client?.revenue?.[0]?.ReplacementTimeline} days`}
                />
              </Col>
              <Col span={12}>
                <DescriptionItem
                  title="Replacement Model"
                  content={`${data?.client?.revenue?.[0]?.ReplacementModel}`}
                />
              </Col>
            </Row>
          </Card>
        ) : null}
        <Card style={{ marginTop: "30px" }}>
          <p className="site-description-item-profile-p">Actions</p>
          <Row gutter={[16, 16]}>
            {closed === false &&
              archived === false &&
              data.status !== "on hold" &&
              data.status !== "no more submissions" && (
                <>
                  <Col span={8}>
                    {" "}
                    <Link
                      target="_blank"
                      to={`/dashboard/referNewApplicant/${data.id}/?job_title=${data.job_title}`}
                    >
                      <Button type="primary" style={{ width: "170px" }}>
                        New Candidate
                      </Button>
                    </Link>
                  </Col>
                  <Col span={8}>
                    <Link
                      target="_blank"
                      to={`/dashboard/existingApplicant/${data.id}/?company_name=${data.company_name}&job_title=${data.job_title}`}
                    >
                      <Button type="primary" style={{ width: "170px" }}>
                        Refer Existing Candidate
                      </Button>
                    </Link>
                  </Col>
                </>
              )}

            {(IsZyvkaTeamMember(data, userState) ||
              userState?.role === "superAdmin") && (
              <>
                <Col span={8}>
                  <Link to={`/dashboard/myjobs1/${data.id}`} target="_blank">
                    <Button type="primary" style={{ width: "170px" }}>
                      View All Candidates
                    </Button>
                  </Link>
                </Col>
                <Col span={8}>
                  <Link to={`/clone/${data.id}`} target="_blank">
                    <Button type="primary" style={{ width: "170px" }}>
                      Clone Job
                    </Button>
                  </Link>
                </Col>
                <Col span={8}>
                  <Button
                    type="primary"
                    style={{ width: "170px" }}
                    onClick={() => {
                      setIsAddNewModalOpen(true);
                    }}
                  >
                    Send Notification
                  </Button>
                </Col>
                <Col span={8}>
                  <Button
                    type="primary"
                    loading={loading}
                    style={{ width: "170px" }}
                    onClick={() => setIsMessageModalOpen(!isMessageModalOpen)}
                  >
                    Chat With Partner
                  </Button>
                </Col>
                <Col span={8}>
                  <Button
                    type="primary"
                    style={{ width: "170px" }}
                    onClick={() => {
                      setEmailModal(true);
                    }}
                  >
                    Send Email To Partners
                  </Button>
                </Col>
                {archived === false && (
                  <Col span={8}>
                    <Button
                      type="primary"
                      style={{ width: "170px" }}
                      onClick={() => archiveJobHandler(data?.id)}
                    >
                      Archive
                    </Button>
                  </Col>
                )}
              </>
            )}
          </Row>
        </Card>
      </Drawer>
      {isAddNewModalOpen ? (
        <Modal
          width={700}
          centered
          visible={isAddNewModalOpen}
          onCancel={handleCancelNotificationModal}
          size={data?.isZyvkaJdAiEnable ? "fullscreen" : "mini"}
          onClose={() => {
            setIsAddNewModalOpen(false);
          }}
          onOk={handleSubmit}
          open={isAddNewModalOpen}
        >
          <Modal.Header>
            Send Group Notification for {data?.job_title}
          </Modal.Header>
          <Modal.Content scrolling>
            <Form onSubmit={handleSubmit}>
              <Form.Input
                type="text"
                fluid
                label="Notification Title"
                name="title"
                required={true}
                value={notiForm?.title}
                onChange={(e) => {
                  setNotiForm({
                    ...notiForm,
                    [e.target.name]: e.target.value,
                  });
                }}
                placeholder="Title"
                width={16}
              />
              <Form.TextArea
                type="text"
                fluid
                label="Notification Text"
                name="body"
                required={true}
                value={notiForm?.body}
                onChange={(e) => {
                  setNotiForm({
                    ...notiForm,
                    [e.target.name]: e.target.value,
                  });
                }}
                placeholder="Enter Notification Message here"
                width={16}
              />
              <Form.Field>
                <label>Notification will be sent to</label>
                <Dropdown
                  placeholder="Select Partners"
                  fluid
                  search
                  multiple
                  selection
                  defaultValue={jobDetails?.assigned_partners}
                  options={AllPartnerAdmins}
                  name="pending_assignment"
                  disabled={true}
                />
              </Form.Field>
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button negative onClick={() => setIsAddNewModalOpen(false)}>
              Cancel
            </Button>
            <Button
              style={{ marginLeft: "30px" }}
              type="primary"
              onClick={handleSubmit}
            >
              Send
            </Button>
          </Modal.Actions>
        </Modal>
      ) : null}
      {isMessageModalOpen && (
        <Modal
          width={700}
          centered
          visible={isMessageModalOpen}
          onCancel={() => setIsMessageModalOpen(false)}
          onOk={handleMessageSubmit}
          open={isMessageModalOpen}
        >
          <Modal.Header>Send Group Message for {data?.job_title}</Modal.Header>
          <Modal.Content scrolling>
            <Form onSubmit={handleMessageSubmit}>
              <Form.TextArea
                fluid
                label="Message Text"
                name="body"
                required={true}
                value={messageForm.body}
                onChange={(e) => {
                  setMessageForm({
                    ...messageForm,
                    [e.target.name]: e.target.value,
                  });
                }}
                placeholder="Enter Message here"
                width={16}
              />
              <Form.Field>
                <label>Message will be sent to</label>
                <Dropdown
                  placeholder="Select Partners"
                  fluid
                  search
                  multiple
                  selection
                  defaultValue={jobDetails?.assigned_partners}
                  options={AllPartnerAdmins}
                  name="pending_assignment"
                  disabled={true}
                />
              </Form.Field>
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button negative onClick={() => setIsMessageModalOpen(false)}>
              Cancel
            </Button>
            <Button
              style={{ marginLeft: "30px" }}
              type="primary"
              onClick={handleMessageSubmit}
            >
              Send
            </Button>
          </Modal.Actions>
        </Modal>
      )}
      <Modal
        size={
          data?.isZyvkaJdAiEnable || data?.jd_urn === "AiGenerated"
            ? "fullscreen"
            : "mini"
        }
        onClose={() => {
          setJdUpdateModal(false);
        }}
        closeOnDimmerClick={true}
        open={jdUpdateModal}
        closeIcon
      >
        <Modal.Header>
          Update/Replace your Job Description for {data?.job_title}
        </Modal.Header>
        <Modal.Content>
          <Form loading={userState?.isPending} onSubmit={updateJd}>
            {data?.isZyvkaJdAiEnable || data?.jd_urn === "AiGenerated" ? (
              <>
                <RichTextEditor
                  initialValue={data?.job_description}
                  getValue={(value) => setnewJd(value)}
                />
              </>
            ) : (
              <>
                <Form.Input
                  type="file"
                  accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                  name="file"
                  onChange={(e) => {
                    setFileObj(e.target.files[0]);
                  }}
                ></Form.Input>
              </>
            )}
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={() => setJdUpdateModal(false)}>
            Cancel
          </Button>
          <Popconfirm
            primary
            title="Are you sure you want to update the job description?"
            onConfirm={updateJd}
            onCancel={() => setJdUpdateModal(false)}
            okText="Yes"
            cancelText="No"
          >
            <Button
              type="primary"
              style={{ marginLeft: "30px" }}
              icon={<EditOutlined />}
            >
              update
            </Button>
          </Popconfirm>
        </Modal.Actions>
      </Modal>

      {emailModal ? (
        <Modal
          width={700}
          centered
          visible={emailModal}
          onCancel={() => setEmailModal(false)}
          size="small"
          onClose={() => setEmailModal(false)}
          onOk={handleEmailSubmit}
          open={emailModal}
        >
          <Modal.Header>
            Send Group Email to Partners for {data?.job_title}
          </Modal.Header>
          <Modal.Content scrolling>
            <Form onSubmit={handleEmailSubmit}>
              <Form.Input
                type="text"
                fluid
                label="Email Subject"
                name="subject"
                required={true}
                value={emailForm?.subject}
                onChange={(e) => {
                  setEmailForm({
                    ...emailForm,
                    [e.target.name]: e.target.value,
                  });
                }}
                placeholder="Title"
                width={16}
              />
              <label style={{ fontWeight: "bolder" }}>Job Description</label>
              <RichTextEditor initialValue="" getValue={getValue} />
              <br />
              <br />
              <br />

              {/* <Form.TextArea
                  type="text"
                  fluid
                  label="Notification Text"
                  name="body"
                  required={true}
                  value={notiForm?.body}
                  onChange={(e) => {
                    setNotiForm({
                      ...notiForm,
                      [e.target.name]: e.target.value,
                    });
                  }}
                  placeholder="Enter Notification Message here"
                  width={16}
                /> */}
              {/* <Form.Field>
                  <label>Notification will be sent to</label>
                  <Dropdown
                    placeholder="Select Partners"
                    fluid
                    search
                    multiple
                    selection
                    defaultValue={jobDetails?.assigned_partners}
                    options={AllPartnerAdmins}
                    name="pending_assignment"
                    disabled={true}
                  />
                </Form.Field> */}
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button negative onClick={() => setEmailModal(false)}>
              Cancel
            </Button>
            <Button
              style={{ marginLeft: "30px", marginBottom: "15px" }}
              type="primary"
              onClick={handleEmailSubmit}
            >
              Send
            </Button>
          </Modal.Actions>
        </Modal>
      ) : null}

      <Modal
        dimmer="inverted"
        size="large"
        zIndex={90}
        onClose={() => {
          setUpdateOpen(false);
        }}
        open={updateOpen}
      >
        <Modal.Header>Update the Position ({data?.job_title})</Modal.Header>
        <Modal.Content scrolling>
          <Form onSubmit={onSave} loading={zyvkaState?.isPending}>
            <Form.Group widths={"16"}>
              <Form.Input
                type="text"
                fluid
                label="Job Title"
                name="job_title"
                required
                value={jobDetails?.job_title}
                onChange={(e) => {
                  setJobDetails({
                    ...jobDetails,
                    [e.target.name]: e.target.value,
                  });
                }}
                placeholder="Enter Job Title"
                width={4}
              />
              <Form.Select
                label="currency"
                name="currencyType"
                options={Currency}
                value={jobDetails?.currencyType}
                onChange={(_e, data) => {
                  setJobDetails({
                    ...jobDetails,
                    [data.name]: data.value,
                  });
                }}
                width={4}
              ></Form.Select>
              <Form.Input
                type="number"
                fluid
                label="Min-CTC"
                name="min_CTC"
                required
                value={jobDetails?.min_CTC}
                error={jobDetails?.min_CTC ? null : "Minimum CTC is Required"}
                onChange={(e) => {
                  setJobDetails({
                    ...jobDetails,
                    [e.target.name]: e.target.value,
                  });
                }}
                placeholder="Enter Minimum CTC"
                width={4}
              />
              <Form.Input
                type="number"
                fluid
                label="Max-CTC"
                name="max_CTC"
                required
                error={jobDetails?.max_CTC ? null : "Maximum CTC is Required"}
                value={jobDetails?.max_CTC}
                onChange={(e) => {
                  setJobDetails({
                    ...jobDetails,
                    [e.target.name]: e.target.value,
                  });
                }}
                width={4}
                placeholder="Enter Max CTC"
              />
            </Form.Group>
            <Form.Group widths={"2"}>
              <Form.Input
                type="number"
                fluid
                label="Min - Exp ( In Years)"
                name="min_experience_req"
                required
                value={jobDetails?.min_experience_req}
                error={
                  jobDetails?.min_experience_req
                    ? null
                    : "This Field is Required"
                }
                onChange={(e) => {
                  setJobDetails({
                    ...jobDetails,
                    [e.target.name]: e.target.value,
                  });
                }}
                placeholder="Enter Minimum Experience"
              />
              <Form.Input
                type="number"
                fluid
                label="Max - Exp ( In Years)"
                name="max_experience_req"
                required
                error={
                  jobDetails?.max_experience_req
                    ? null
                    : "This Field is Required"
                }
                value={jobDetails?.max_experience_req}
                onChange={(e) => {
                  setJobDetails({
                    ...jobDetails,
                    [e.target.name]: e.target.value,
                  });
                }}
                placeholder="Enter Maximum Experience"
              />
            </Form.Group>
            <Form.Group widths={"2"}>
              {data?.status !== "open" && (
                <Form.Select
                  label="Status"
                  name="status"
                  options={[
                    { key: 1, text: "Open", value: "open" },
                    {
                      key: 2,
                      text: "Candidate offered",
                      value: "candidate offered",
                    },
                    {
                      key: 3,
                      text: " Candidate accepted",
                      value: "candidate accepted",
                    },
                    {
                      key: 4,
                      text: "Candidate joined",
                      value: "candidate joined",
                    },
                    { key: 5, text: "On hold", value: "on hold" },
                    {
                      key: 6,
                      text: "No more submissions",
                      value: "no more submissions",
                    },
                    { key: 7, text: "Closed", value: "closed" },
                    { key: 8, text: "Slot Pending", value: "Slot Pending" },
                    {
                      key: 9,
                      text: "Requirement Changed",
                      value: "Requirement Changed",
                    },
                  ]}
                  value={jobDetails?.status}
                  onChange={(_e, data) => {
                    setJobDetails({
                      ...jobDetails,
                      [data.name]: data.value,
                    });
                  }}
                ></Form.Select>
              )}
              <Form.Input
                type="number"
                fluid
                label="Number of Position"
                name="number_of_positions"
                required
                error={
                  jobDetails?.number_of_positions
                    ? null
                    : "Number of Position is Required"
                }
                value={jobDetails?.number_of_positions}
                onChange={(e) => {
                  setJobDetails({
                    ...jobDetails,
                    [e.target.name]: e.target.value,
                  });
                }}
                placeholder="Number of Positions"
              />
              <Form.Checkbox
                toggle
                style={{ marginTop: "30px" }}
                name="hotJob"
                label="Hot Job ?"
                checked={jobDetails?.hotJob}
                onChange={(e, data) => {
                  setJobDetails({ ...jobDetails, [data.name]: data.checked });
                }}
              ></Form.Checkbox>
              <Form.Checkbox
                toggle
                style={{ marginTop: "30px" }}
                name="openToInternalTeamOnly"
                label="Open to internalTeam only ?"
                checked={jobDetails?.openToInternalTeamOnly}
                onChange={(e, data) => {
                  data?.checked
                    ? setJobDetails({
                        ...jobDetails,
                        [data.name]: data.checked,
                        internalTeamWorking: true,
                      })
                    : setJobDetails({
                        ...jobDetails,
                        [data.name]: data.checked,
                      });
                }}
              ></Form.Checkbox>
              <Form.Checkbox
                toggle
                style={{ marginTop: "30px" }}
                name="internalTeamWorking"
                label="Internal Team Working ?"
                checked={jobDetails?.internalTeamWorking}
                onChange={(e, data) => {
                  setJobDetails({ ...jobDetails, [data.name]: data.checked });
                }}
              ></Form.Checkbox>
              {jobDetails?.isZyvkaAiEnable ? (
                userState?.role === "superAdmin" && (
                  <Form.Checkbox
                    toggle
                    style={{ marginTop: "30px" }}
                    name="isZyvkaAiEnable"
                    label="Enable ZyvkaAI ?"
                    checked={jobDetails?.isZyvkaAiEnable}
                    onChange={(e, data) => {
                      setJobDetails({
                        ...jobDetails,
                        [data.name]: data.checked,
                      });
                    }}
                  />
                )
              ) : (
                <Form.Checkbox
                  toggle
                  style={{ marginTop: "30px" }}
                  name="isZyvkaAiEnable"
                  label="Enable ZyvkaAI ?"
                  checked={jobDetails?.isZyvkaAiEnable}
                  onChange={(e, data) => {
                    setJobDetails({ ...jobDetails, [data.name]: data.checked });
                  }}
                />
              )}
            </Form.Group>{" "}
            <label>description</label>
            <RichTextEditor
              style={{ zIndex: "90" }}
              initialValue={jobDetails?.new_job_description}
              getValue={(e) => {
                setJobDetails({ ...jobDetails, new_job_description: e });
              }}
            />
            <Form.Select
              defaultValue={jobDetails?.skills_req}
              options={skills}
              search
              required={true}
              name="skills_req"
              value={jobDetails?.skills_req}
              onChange={(_e, data) => {
                setJobDetails({ ...jobDetails, [data.name]: data.value });
              }}
              multiple
              label="select skills"
            ></Form.Select>
            <Form.Input
              text
              placeholder="Enter Video Link of Jd"
              name="jdVideo_urn"
              value={jobDetails?.jdVideo_urn}
              onChange={(e, data) => {
                setJobDetails({ ...jobDetails, [data.name]: data.value });
              }}
              label="Jd Video Url"
            ></Form.Input>
            <Form.Field>
              <label>Select Zyvka Member</label>
              <Dropdown
                placeholder="Select Zyvka Team"
                fluid
                search
                selection
                multiple
                name="zyvka_team_members"
                defaultValue={jobDetails?.zyvka_team_members}
                onChange={(e, data) => {
                  setJobDetails({ ...jobDetails, [data.name]: data.value });
                }}
                options={AllZyvkaUsers}
              />
            </Form.Field>
            {jobDetails.openToInternalTeamOnly === false && (
              <>
                <Form.Field>
                  <label>Pending Claim/Assigned</label>
                  <Dropdown
                    placeholder="Select Partners"
                    fluid
                    search
                    multiple
                    selection
                    defaultValue={jobDetails?.pending_assignment}
                    name="pending_assignment"
                    onChange={(e, data) => {
                      setJobDetails({ ...jobDetails, [data.name]: data.value });
                    }}
                    options={AllPartnerAdmins}
                    disabled={true}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Select Partner Admins</label>
                  <Dropdown
                    placeholder="Select Partners"
                    fluid
                    search
                    multiple
                    selection
                    defaultValue={jobDetails?.assigned_partners}
                    name="assigned_partners"
                    onChange={(e, data) => {
                      setJobDetails({ ...jobDetails, [data.name]: data.value });
                    }}
                    options={partnerOptions}
                  />
                </Form.Field>
              </>
            )}
            <Form.Field>
              <label>Revenue Model </label>
              <Dropdown
                placeholder="Revenue Model"
                fluid
                search
                selection
                required
                name="revenue_model"
                defaultValue={jobDetails?.revenue_model}
                onChange={(e, data) => {
                  setJobDetails({ ...jobDetails, [data.name]: data.value });
                }}
                options={revenueOptions}
              />
            </Form.Field>
            <Form.Input
              type="number"
              fluid
              required
              label="Charge Value"
              name="charge_value"
              error={jobDetails?.charge_value ? null : "Value  is Required"}
              value={jobDetails?.charge_value}
              onChange={(e) => {
                setJobDetails({
                  ...jobDetails,
                  [e.target.name]: e.target.value,
                });
              }}
              placeholder="Charge Value"
            />
            <Form.Field>
              <label>Currency </label>
              <Dropdown
                placeholder="Currency"
                fluid
                search
                required
                selection
                name="currency"
                defaultValue={jobDetails?.currency}
                onChange={(e, data) => {
                  setJobDetails({ ...jobDetails, [data.name]: data.value });
                }}
                options={Currency}
              />
            </Form.Field>
            <Form.Input
              type="number"
              fluid
              required
              label="Payment Term"
              name="payment_term"
              error={
                jobDetails?.payment_term ? null : "Payment Term  is Required"
              }
              value={jobDetails?.payment_term}
              onChange={(e) => {
                setJobDetails({
                  ...jobDetails,
                  [e.target.name]: e.target.value,
                });
              }}
              placeholder="Payment Term"
            />
            <Form.Input
              type="number"
              fluid
              required
              label="Invoicing Term"
              name="invoicing_term"
              error={
                jobDetails?.invoicing_term ? null : "Invoicing Term is Required"
              }
              value={jobDetails?.invoicing_term}
              onChange={(e) => {
                setJobDetails({
                  ...jobDetails,
                  [e.target.name]: e.target.value,
                });
              }}
              placeholder="Invoicing Term"
            />
            <Form.Input
              type="number"
              required
              fluid
              label="Repalcement Timeline"
              name="replacement_timeline"
              error={
                jobDetails?.replacement_timeline
                  ? null
                  : "Replacement Timeline is Required"
              }
              value={jobDetails?.replacement_timeline}
              onChange={(e) => {
                setJobDetails({
                  ...jobDetails,
                  [e.target.name]: e.target.value,
                });
              }}
              placeholder="Replacement Timeline"
            />
            <Form.Field>
              <label>Repalcement Model </label>
              <Dropdown
                placeholder="Replacement Model"
                fluid
                search
                selection
                required
                name="replacement_model"
                defaultValue={jobDetails?.replacement_model}
                onChange={(e, data) => {
                  setJobDetails({ ...jobDetails, [data.name]: data.value });
                }}
                options={modelOptions}
              />
            </Form.Field>
            <Form.Button
              style={{
                background: "#6855f6",
                color: "#ffffff",
              }}
              type="submit"
            >
              Save
            </Form.Button>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={() => setUpdateOpen(false)}>
            Cancel
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
};

export default ViewJobDetails;
