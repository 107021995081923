import React, { useState, useEffect } from "react";
import {
    Button,
    Typography,
    Spin,
    Divider,
    Form,
} from "antd";
import {
    FireOutlined,
    LaptopOutlined,
    BarChartOutlined,
    LeftOutlined, RightOutlined
} from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import JobAssignDetails from "./JobAssignDetails";
import {
    GetPartnerHotJobs,
    GetPartnerTechJobs,
    GetPartnerNonTechJobs,
} from "../api/ZyvkaMember";
import { fetchAllSkills, getAllJobProfiles } from "../api/auth";
import { UserState } from "../features/userSlice";
import { selectPartnerHotJobs, selectPartnerNonTechJobs, selectPartnerTechJobs } from "../features/partnersSlice";
import JobFilters from "./JobFilter";
import Search from "antd/lib/input/Search";
import axios from "axios";

const { Title } = Typography;
const PartnerJobList = () => {
    const [form] = Form.useForm();
    const [flag, setFlag] = useState(false);
    const [skillOption, setSkillOption] = useState([]);
    const [filteredHotJobs, setFilteredHotJobs] = useState([]);
    const [filteredTechJobs, setFilteredTechJobs] = useState([]);
    const [filteredNonTechJobs, setFilteredNonTechJobs] = useState([]);
    const [filterOpen, setFilterOpen] = useState(false);
    const [opendrawer, setOpenDrawer] = useState(false);
    
    const userState = useSelector(UserState);
    const reduxHotJobs = useSelector(selectPartnerHotJobs);
    const reduxTechJobs = useSelector(selectPartnerTechJobs);
    const reduxNonTechJobs = useSelector(selectPartnerNonTechJobs);
    
    const dispatch = useDispatch();

    // Compute the current jobs to display
    const HotJobsData = filterOpen ? filteredHotJobs : reduxHotJobs;
    const TechJobsData = filterOpen ? filteredTechJobs : reduxTechJobs;
    const NonTechJobsData = filterOpen ? filteredNonTechJobs : reduxNonTechJobs;

    const ChangeFlag = () => {
        setFlag((flag) => !flag);
    };

    const showDrawer = () => {
        setOpenDrawer(true);
    };
    
    const onClose = () => {
        setOpenDrawer(false);
    };

    const fetchHotJobs = async () => {
        try {
            await GetPartnerHotJobs(dispatch);
        } catch (error) {
            console.log(error);
        }
    };
    
    const fetchTechJobs = async () => {
        try {
            await GetPartnerTechJobs(dispatch);
        } catch (error) {
            console.log(error);
        }
    };
    
    const fetchNonTechJobs = async () => {
        try {
            await GetPartnerNonTechJobs(dispatch);
        } catch (error) {
            console.log(error);
        }
    };
    
    const fetchJobProfiles = async () => {
        try {
            await getAllJobProfiles(dispatch);
        } catch (error) {
            console.log(error);
        }
    };
    
    const fetchSkills = async () => {
        try {
            await fetchAllSkills(dispatch, setSkillOption);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchHotJobs();
        fetchNonTechJobs();
        fetchTechJobs();
        fetchJobProfiles();
        fetchSkills();
    }, [flag]);

    const processFilteredJobs = (jobs) => {
        const hotJobs = jobs.filter(job => job.hotJob === true);
        const techJobs = jobs.filter(job => job.jobType === "Tech" && !job.hotJob);
        const nonTechJobs = jobs.filter(job => job.jobType === "Non-Tech" && !job.hotJob);
        
        setFilteredHotJobs(hotJobs);
        setFilteredTechJobs(techJobs);
        setFilteredNonTechJobs(nonTechJobs);
    };

    const onFinish = async (values) => {
        try {
            const result = await axios.post(
                `${process.env.REACT_APP_SERVER_URL}/api/partner/fiterJobsForPartnersClaim`,
                {
                    job_title: values.job_title,
                    min_experience_req: values.min_experience_req,
                    max_experience_req: values.max_experience_req,
                    min_ctc: values.min_ctc,
                    max_ctc: values.max_ctc,
                    location: values.location,
                    min_notice_period: values.min_notice_period,
                    max_notice_period: values.max_notice_period,
                    status: values.status,
                    prefered_industry: values.prefered_industry,
                    skills_req: values.skills_req,
                    myjobs: false,
                    active_status: 1,
                    hotJob: values.hotJob === "yes" ? true : false,
                    jobType: values.jobType
                },
                { withCredentials: true }
            );
            
            processFilteredJobs(result.data);
            setFilterOpen(true);
        } catch (error) {
            console.error("Filter error:", error);
        }
    };

    const AllSkills = userState.gSkills;
    const AllProfiles = userState.gProfile;
    const [componentSize, setComponentSize] = useState("default");

    const renderJobCarousel = (jobs, id, title, icon) => (
        <>
            <Divider orientation="left">
                <Title level={3}>
                    {icon} {title}
                </Title>
            </Divider>
            <div className="job-carousel" id={id} style={{ display: "flex", justifyItems: "center", position: "relative" }}>
                <Button style={{
                    position: "sticky",
                    top: '50%',
                    zIndex: 10,
                    left: 0,
                    width: "40px",
                    paddingLeft: "5px",
                    borderColor: "#6855f6",
                    boxShadow: "1px 2px 9px #B3A8FF"
                }} onClick={() => {
                    document.getElementById(id).scrollLeft -= 400;
                }}
                icon={<LeftOutlined style={{
                    color: "#6855f6",
                    width: "40px",
                }} />}
                />
                {jobs.map((data, index) => (
                    <div key={data.id} style={{ marginLeft: index === 0 ? '-25px' : '0px' }}>
                        <JobAssignDetails
                            data={data}
                            width={370}
                            ChangeFlag={ChangeFlag}
                            skills={skillOption}
                        />
                    </div>
                ))}
                <Button style={{
                    position: "sticky",
                    width: "40px",
                    paddingLeft: "5px",
                    right: 20,
                    top: '50%',
                    zIndex: 10,
                    borderColor: "#6855f6",
                    boxShadow: "1px 2px 9px #B3A8FF"
                }} onClick={() => {
                    document.getElementById(id).scrollLeft += 400;
                }}
                icon={<RightOutlined style={{
                    color: "#6855f6",
                    width: "40px",
                }} />}
                />
            </div>
        </>
    );

    return (
        <div style={{ height: "100vh", overflow: "scroll" }}>
            <br />
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                    gap: "10px",
                }}
                className="searchbox"
            >
                 <Button onClick={()=>setOpenDrawer(!opendrawer)}>Filter</Button>
                {opendrawer && (
                    <JobFilters
                        form={form}
                        onFinish={onFinish}
                        skillOptions={AllSkills}
                        jobProfiles={AllProfiles}
                        onReset={() => {
                            form.resetFields();
                            setFlag(flag => !flag);
                            setFilterOpen(false);
                            setFilteredHotJobs([]);
                            setFilteredTechJobs([]);
                            setFilteredNonTechJobs([]);
                        }}
                        onClose={onClose}
                        componentSize={componentSize}
                        filterState={opendrawer}
                        setFilterState={setOpenDrawer}
                    />
                )}
            </div>
            
            {userState.isPending ? (
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start" }}>
                    <Spin size="large" />
                </div>
            ) : (
                <>
                    {renderJobCarousel(HotJobsData, "1", "Hot Jobs", <FireOutlined style={{ color: "orange" }} />)}
                    {renderJobCarousel(TechJobsData, "2", "Tech Jobs", <LaptopOutlined style={{ color: "green" }} />)}
                    {renderJobCarousel(NonTechJobsData, "3", "Non-Tech Jobs", <BarChartOutlined style={{ color: "blue" }} />)}
                </>
            )}
        </div>
    );
};

export default PartnerJobList;