
export const attachmentTypes = {
    image: 'image',
    video: 'video',
    audio: 'audio',
    file: 'file',
    link: 'link',
    voice: 'voice'
  }
  
  export const CHANNEL_TYPE = {
    GROUP: 'group',
    BROADCAST: 'broadcast',
    DIRECT: 'direct',
    PUBLIC: 'public',
    PRIVATE: 'private'
  }
  
  export const MESSAGE_STATUS = {
    UNMODIFIED: 'unmodified',
    EDIT: 'edited',
    DELETE: 'deleted',
    FAILED: 'failed'
  }
  
  export const messagesCustomColor = {
    ownMessageBackground: '#E3E7FF',
    incomingMessageBackground: '#f1f2f6',
    ownRepliedMessageBackground: '#f9fbfd',
    incomingRepliedMessageBackground: '#fbfbfc',
  };
  
  export const MESSAGE_DELIVERY_STATUS = {
    PENDING: 'pending',
    SENT: 'sent',
    DELIVERED: 'received',
    READ: 'displayed'
  }
  
  export const USER_STATE = {
    ACTIVE: 'active',
    INACTIVE: 'inactive',
    DELETED: 'deleted'
  }