// MessageHeader.jsx
import React from 'react';
import { makeUsername } from '../../helpers';
import './MessageHeader.css';

function MessageHeader({
  message,
  withAttachments,
  withMediaAttachment,
  notLinkAttachment,
  showMessageSenderName,
  currentUser,
  handleCreateChat,
  showOwnSenderName
}) {
  const messageOwnerIsNotCurrentUser = !!(
    message.user &&
    message.user.id !== currentUser.id &&
    message.user.id
  );

  const handleOpenCreateChat = (user) => {
    if (user.id !== currentUser.id) {
      handleCreateChat(user);
    }
  };

  const getHeaderClasses = () => {
    const classes = ['message-header'];
    
    if (message.parentMessage) classes.push('is-replied');
    if (message.forwardingDetails) classes.push('is-forwarded');
    if (message.body) classes.push('has-message-body');
    if (withMediaAttachment) classes.push('with-media-attachment');
    if (withAttachments && notLinkAttachment) classes.push('with-padding');
    
    return classes.join(' ');
  };

  const getOwnerClasses = () => {
    const classes = ['message-owner'];
    if (!message.incoming) classes.push('rtl-direction');
    if (messageOwnerIsNotCurrentUser) classes.push('clickable');
    return classes.join(' ');
  };

  return (
    <div className={getHeaderClasses()}>
      {showMessageSenderName && (
        <h3
          className={getOwnerClasses()}
          onClick={() => handleOpenCreateChat(message.user)}
        >
          {message.user.id === currentUser.id && message.user.firstName
            ? !showOwnSenderName 
              ? null 
              : `${message.user.firstName} ${message.user.lastName}`
            : makeUsername(message.user, false)}
        </h3>
      )}
    </div>
  );
}

export default MessageHeader;