import { createSlice } from '@reduxjs/toolkit';

const chatSlice = createSlice({
  name: 'chat',
  initialState: {
    unreadCount: 0,
    clientId: null,
    chatToken:null,
  },
  reducers: {
    setUnreadCount: (state, action) => {
      state.unreadCount = action.payload;
    },
    setClientId: (state, action) => {
      state.clientId = action.payload;
    },
    setChatToken: (state, action) => {
      state.chatToken = action.payload;
    },
  },
});

export const { setUnreadCount } = chatSlice.actions;
export const { setClientId } = chatSlice.actions;
export const {setChatToken} = chatSlice.actions;

export const selectUnreadCount = (state) => state.chat.unreadCount;
export const selectClientId = (state) => state.chat.clientId;
export const selectChatToken = (state) => state.chat.chatToken;

export default chatSlice.reducer;