import React from 'react'
import ChatComponent from './ChatComponent'
const ChatPages = () => {
  return (
    <div style={{
      marginLeft: '-25px',
      marginTop: '0px',
      marginBottom:"-20px",
      height: "calc(100vh - 65px)",
      width: 'calc(100% + 73px)', 
      overflow: 'hidden'
    }}>
      <ChatComponent />
    </div>
  )
}

export default ChatPages