import styled from 'styled-components';
import { ReactComponent as CloseSvg } from '../assets/svg/close.svg';

// Colors
export const colors = {
  white: '#ffffff',
  black: '#000000',
  dark: '#161616',
  blue: '#438CED',
  backgroundColor: '#f1f2f6',
  textColor1: '#111539',
  textColor2: '#707388',
  textColor3: '#A0A1B0',
  placeholderTextColor: '#acacad',
  gray0: '#F3F5F8',
  gray1: '#EDEDED',
  borderColor: '#dfe0eb',
  primary: 'rgba(104, 85, 246)',
  primaryLight: '#E3E7FF',
  errorBlur: '#d7596c'
};

// Avatar Colors
let _avatarColors = ['#FF3E74', '#4F6AFF', '#FBB019', '#00CC99', '#9F35E7', '#63AFFF'];

export const setAvatarColor = (colors) => {
  _avatarColors = colors;
};

export const getAvatarColors = () => _avatarColors;

// Utility Functions
export function md5(inputString) {
  const hc = '0123456789abcdef';
  
  function rh(n) {
    let j;
    let s = '';
    for (j = 0; j <= 3; j++) s += hc.charAt((n >> (j * 8 + 4)) & 0x0f) + hc.charAt((n >> (j * 8)) & 0x0f);
    return s;
  }
  
  function ad(x, y) {
    const l = (x & 0xffff) + (y & 0xffff);
    const m = (x >> 16) + (y >> 16) + (l >> 16);
    return (m << 16) | (l & 0xffff);
  }
  
  function rl(n, c) {
    return (n << c) | (n >>> (32 - c));
  }
  
  function cm(q, a, b, x, s, t) {
    return ad(rl(ad(ad(a, q), ad(x, t)), s), b);
  }
  
  function ff(a, b, c, d, x, s, t) {
    return cm((b & c) | (~b & d), a, b, x, s, t);
  }
  
  function gg(a, b, c, d, x, s, t) {
    return cm((b & d) | (c & ~d), a, b, x, s, t);
  }
  
  function hh(a, b, c, d, x, s, t) {
    return cm(b ^ c ^ d, a, b, x, s, t);
  }
  
  function ii(a, b, c, d, x, s, t) {
    return cm(c ^ (b | ~d), a, b, x, s, t);
  }
  
  function sb(x) {
    let i;
    const nblk = ((x.length + 8) >> 6) + 1;
    const blks = new Array(nblk * 16);
    for (i = 0; i < nblk * 16; i++) blks[i] = 0;
    for (i = 0; i < x.length; i++) blks[i >> 2] |= x.charCodeAt(i) << ((i % 4) * 8);
    blks[i >> 2] |= 0x80 << ((i % 4) * 8);
    blks[nblk * 16 - 2] = x.length * 8;
    return blks;
  }
  
  let i;
  const x = sb(inputString);
  let a = 1732584193;
  let b = -271733879;
  let c = -1732584194;
  let d = 271733878;
  let olda;
  let oldb;
  let oldc;
  let oldd;
  
  for (i = 0; i < x.length; i += 16) {
    olda = a
    oldb = b
    oldc = c
    oldd = d
    a = ff(a, b, c, d, x[i], 7, -680876936)
    d = ff(d, a, b, c, x[i + 1], 12, -389564586)
    c = ff(c, d, a, b, x[i + 2], 17, 606105819)
    b = ff(b, c, d, a, x[i + 3], 22, -1044525330)
    a = ff(a, b, c, d, x[i + 4], 7, -176418897)
    d = ff(d, a, b, c, x[i + 5], 12, 1200080426)
    c = ff(c, d, a, b, x[i + 6], 17, -1473231341)
    b = ff(b, c, d, a, x[i + 7], 22, -45705983)
    a = ff(a, b, c, d, x[i + 8], 7, 1770035416)
    d = ff(d, a, b, c, x[i + 9], 12, -1958414417)
    c = ff(c, d, a, b, x[i + 10], 17, -42063)
    b = ff(b, c, d, a, x[i + 11], 22, -1990404162)
    a = ff(a, b, c, d, x[i + 12], 7, 1804603682)
    d = ff(d, a, b, c, x[i + 13], 12, -40341101)
    c = ff(c, d, a, b, x[i + 14], 17, -1502002290)
    b = ff(b, c, d, a, x[i + 15], 22, 1236535329)
    a = gg(a, b, c, d, x[i + 1], 5, -165796510)
    d = gg(d, a, b, c, x[i + 6], 9, -1069501632)
    c = gg(c, d, a, b, x[i + 11], 14, 643717713)
    b = gg(b, c, d, a, x[i], 20, -373897302)
    a = gg(a, b, c, d, x[i + 5], 5, -701558691)
    d = gg(d, a, b, c, x[i + 10], 9, 38016083)
    c = gg(c, d, a, b, x[i + 15], 14, -660478335)
    b = gg(b, c, d, a, x[i + 4], 20, -405537848)
    a = gg(a, b, c, d, x[i + 9], 5, 568446438)
    d = gg(d, a, b, c, x[i + 14], 9, -1019803690)
    c = gg(c, d, a, b, x[i + 3], 14, -187363961)
    b = gg(b, c, d, a, x[i + 8], 20, 1163531501)
    a = gg(a, b, c, d, x[i + 13], 5, -1444681467)
    d = gg(d, a, b, c, x[i + 2], 9, -51403784)
    c = gg(c, d, a, b, x[i + 7], 14, 1735328473)
    b = gg(b, c, d, a, x[i + 12], 20, -1926607734)
    a = hh(a, b, c, d, x[i + 5], 4, -378558)
    d = hh(d, a, b, c, x[i + 8], 11, -2022574463)
    c = hh(c, d, a, b, x[i + 11], 16, 1839030562)
    b = hh(b, c, d, a, x[i + 14], 23, -35309556)
    a = hh(a, b, c, d, x[i + 1], 4, -1530992060)
    d = hh(d, a, b, c, x[i + 4], 11, 1272893353)
    c = hh(c, d, a, b, x[i + 7], 16, -155497632)
    b = hh(b, c, d, a, x[i + 10], 23, -1094730640)
    a = hh(a, b, c, d, x[i + 13], 4, 681279174)
    d = hh(d, a, b, c, x[i], 11, -358537222)
    c = hh(c, d, a, b, x[i + 3], 16, -722521979)
    b = hh(b, c, d, a, x[i + 6], 23, 76029189)
    a = hh(a, b, c, d, x[i + 9], 4, -640364487)
    d = hh(d, a, b, c, x[i + 12], 11, -421815835)
    c = hh(c, d, a, b, x[i + 15], 16, 530742520)
    b = hh(b, c, d, a, x[i + 2], 23, -995338651)
    a = ii(a, b, c, d, x[i], 6, -198630844)
    d = ii(d, a, b, c, x[i + 7], 10, 1126891415)
    c = ii(c, d, a, b, x[i + 14], 15, -1416354905)
    b = ii(b, c, d, a, x[i + 5], 21, -57434055)
    a = ii(a, b, c, d, x[i + 12], 6, 1700485571)
    d = ii(d, a, b, c, x[i + 3], 10, -1894986606)
    c = ii(c, d, a, b, x[i + 10], 15, -1051523)
    b = ii(b, c, d, a, x[i + 1], 21, -2054922799)
    a = ii(a, b, c, d, x[i + 8], 6, 1873313359)
    d = ii(d, a, b, c, x[i + 15], 10, -30611744)
    c = ii(c, d, a, b, x[i + 6], 15, -1560198380)
    b = ii(b, c, d, a, x[i + 13], 21, 1309151649)
    a = ii(a, b, c, d, x[i + 4], 6, -145523070)
    d = ii(d, a, b, c, x[i + 11], 10, -1120210379)
    c = ii(c, d, a, b, x[i + 2], 15, 718787259)
    b = ii(b, c, d, a, x[i + 9], 21, -343485551)
    a = ad(a, olda)
    b = ad(b, oldb)
    c = ad(c, oldc)
    d = ad(d, oldd)
  }
  
  return rh(a) + rh(b) + rh(c) + rh(d);
}

// Styled Components
export const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin: ${props => props.margin};
  margin-top: ${props => props.marginTop};
  margin-bottom: ${props => props.marginBottom};
  margin-right: ${props => props.marginRight};
  margin-left: ${props => props.marginLeft};
  align-items: ${props => props.align};
  justify-content: ${props => props.justify};
  width: ${props => props.width};
  height: ${props => props.height};
  min-height: ${props => props.minHeight};
  padding: ${props => props.padding};
  padding-left: ${props => props.paddingLeft};
  padding-right: ${props => props.paddingRight};
  flex-wrap: ${props => props.flexWrap};
`;

export const Button = styled.button`
  display: inline-block;
  box-sizing: border-box;
  text-decoration: none;
  outline: none;
  cursor: ${props => !props.disabled && 'pointer'};
  text-align: center;
  font-style: normal;
  border-radius: ${props => props.borderRadius || '4px'};
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  padding: 8px 16px;
  background-color: ${props => props.backgroundColor || colors.white};
  color: ${props => props.color};
  border: 1px solid ${props => props.backgroundColor || colors.gray1};
  margin: ${props => props.margin || '0'};
  user-select: none;
  transition: opacity 0.1s;
  opacity: ${props => props.disabled ? 0.5 : 1};
  &:hover,
  &:focus {
    opacity: ${props => props.disabled ? 0.5 : 0.8};
  }
`;

export const ButtonBlock = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${props => props.marginTop || '10px'};
  padding-right: ${props => props.paddingRight || '0px'};
  justify-content: ${props => props.justify || 'flex-end'};
  height: ${props => props.height};
  min-height: ${props => props.height};
  background-color: ${props => props.backgroundColor};
`;

export const Label = styled.label`
  display: inline-block;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  margin-top: 20px;
  margin-bottom: 4px;
  color: ${props => props.color};
`;

export const CustomInput = styled.input`
  height: ${props => props.height || '40px'};
  width: ${props => props.width || '100%'};
  background: ${props => colors.white};
  border: ${props => props.error ? `1px solid ${props.errorColor}` : `1px solid ${colors.gray1}`};
  color: ${props => props.color};
  box-sizing: border-box;
  border-radius: 8px;
  padding: 11px 14px;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  opacity: 1;
  outline: none;

  &:focus {
    border: 1px solid ${props => props.error ? props.errorColor : colors.primary};
    outline: ${props => props.error ? `1px solid ${colors.errorBlur}` : `2px solid ${props.borderColor}`};
  }
  
  &:disabled {
    background-color: ${colors.gray0};
    opacity: 1;
    color: #383b51;
  }
  
  &::placeholder {
    opacity: 1;
    color: ${props => props.placeholderColor};
  }
`;

export const Popup = styled.div`
  position: relative;
  min-height: 150px;
  min-width: ${props => props.minWidth || '400px'};
  max-width: ${props => props.maxWidth || '600px'};
  max-height: ${props => props.maxHeight || '650px'};
  width: ${props => props.width || 'unset'};
  height: ${props => props.height || 'unset'};
  display: ${props => props.display || 'flex'};
  flex-direction: column;
  padding: ${props => props.padding ? props.padding : '22px 24px'};
  background: ${props => props.backgroundColor || colors.white};
  box-shadow: ${props => props.boxShadow || '4px 4px 30px rgba(0, 0, 0, 0.06)'};
  border-radius: 8px;
  box-sizing: border-box;
`;

export const PopupBody = styled.div`
  padding: ${props => `${props.paddingV || 0} ${props.paddingH || 0}`};
  margin-bottom: 8px;
  height: ${props => props.withFooter ? `calc(100% - (54px + ${props.paddingV}))` : 'calc(100% - 54px)'};
`;

export const PopupName = styled.h3`
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: ${props => props.color};
  margin: 0;
  margin-top: ${props => props.marginTop};
  margin-bottom: ${props => props.marginBottom};
  padding: ${props => props.padding};
  word-break: break-word;
`;

export const CloseIcon = styled(CloseSvg)`
  position: absolute;
  top: 13px;
  right: 13px;
  cursor: pointer;
  padding: 15px;
  box-sizing: content-box;
  color: ${props => props.color};
`;