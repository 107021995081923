import React from 'react';
import { Form, Input } from "semantic-ui-react";
import { DatePicker, Button } from "antd";

const CandidateFilter = ({ 
  filterState,
  setFilterState,
  keySkills,
  setKeySkills,
  current_company,
  set_current_company,
  not_current_company,
  set_not_current_company,
  clientName,
  setClientName,
  onFinish,
  resetFilters,
  onClose,
  AllSkills,
  AllCompany,
  AllClients,
  roundOptions,
  isPartner,
  compName
}) => {
  return (
    <div style={{ width: "100%", padding: "10px" }}>
      <Form
        labelCol={{
          span: 4,
        }}
        layout="horizontal"
        onSubmit={onFinish}
      >
        <Form.Group widths="equal" fluid style={{ display: "flex", flexDirection: "flexWrap" }}>
          <Form.Input 
            fluid 
            name="min_experience" 
            placeholder="min experience (in years)" 
            type="number" 
            value={filterState?.min_total_experience} 
            onChange={(e) => {
              setFilterState({
                ...filterState,
                min_total_experience: e.target.value,
              });
            }}
            style={{ minWidth: "150px", minHeight: "30px", height: "40px" }}
          />
          <Form.Input 
            fluid 
            name="max_experience" 
            placeholder="max experience(in years)" 
            type="number"
            value={filterState?.max_total_experience}
            onChange={(e) => {
              setFilterState({
                ...filterState,
                max_total_experience: e.target.value,
              });
            }}
            style={{ minWidth: "150px", minHeight: "30px", height: "40px" }}
          />
          <Form.Input 
            name="max_notice_period"
            placeholder="notice period(in days)"
            type="number"
            value={filterState?.max_notice_period}
            onChange={(e) => {
              setFilterState({
                ...filterState,
                [e.target.name]: e.target.value,
              });
            }}
            style={{ minWidth: "150px", minHeight: "30px", height: "40px" }}
          />
          <Form.Select
            name="salary_type"
            placeholder="salary currency"
            value={filterState?.salary_type}
            onChange={(e, { value }) => {
              setFilterState({
                ...filterState,
                salary_type: value,
              });
            }}
            selection
            options={[
              { key: 1, text: "$ USD", value: "USD" },
              { key: 2, text: "₹ INR", value: "INR" },
              { key: 3, text: "€ EURO", value: "EURO" },
            ]}
          />
          <Form.Input 
            name="current_ctc"
            placeholder="current ctc"
            type="number"
            value={filterState?.max_current_salary}
            onChange={(e) => {
              setFilterState({
                ...filterState,
                max_current_salary: e.target.value,
              });
            }}
            style={{ minWidth: "150px", minHeight: "30px", height: "40px" }}
          />
        </Form.Group>

        <Form.Group widths="equal" style={{ display: "flex", flexDirection: "wrap" }}>
          <Form.Input 
            name="expected_salary"
            placeholder="expected salary"
            type="number"
            value={filterState?.max_expected_salary}
            onChange={(e) => {
              setFilterState({
                ...filterState,
                max_expected_salary: e.target.value,
              });
            }}
            style={{ minWidth: "150px", minHeight: "30px", height: "40px" }}
          />
          <Form.Input
            name="location"
            value={filterState?.location}
            onChange={(e, { value }) => {
              setFilterState({
                ...filterState,
                location: value
              });
            }}
            type="text"
            placeholder="City, Country"
            fluid
            style={{ minWidth: "150px", minHeight: "30px", height: "40px" }}
          />
          <Form.Select
            name="candidate_status"
            placeholder="candidate status"
            fluid
            selection
            value={filterState?.current_job_status}
            style={{ minWidth: "150px", minHeight: "30px", height: "40px" }}
            options={roundOptions?.map((data, idx) => ({
              key: idx,
              value: data,
              text: data,
            }))}
            onChange={(e, { value }) => {
              setFilterState({
                ...filterState,
                current_job_status: value,
              });
            }}
          />
          <Form.Select
            additionLabel={null}
            selection
            fluid
            search
            placeholder="Key skills"
            name="key_skills"
            multiple
            value={keySkills || []}
            onChange={(event, { value }) => setKeySkills(value)}
            options={AllSkills?.map((data, idx) => ({
              key: idx,
              text: data.skill,
              value: data.skill
            }))}
            style={{ minWidth: "150px", minHeight: "30px", height: "40px" }}
          />
        </Form.Group>

        <Form.Group widths="equal" style={{ display: "flex", flexDirection: "wrap" }}>
          {!isPartner && (
            <Form.Select
              name="partner_company_name"
              placeholder="partner company name"
              search
              style={{ minWidth: "150px" }}
              value={filterState?.partner_company_name}
              onChange={(e, { value }) => {
                setFilterState({
                  ...filterState,
                  partner_company_name: value,
                });
              }}
              options={compName?.map((data, idx) => ({
                key: idx,
                value: data.company_name,
                text: data.company_name
              }))}
            />
          )}
          <Form.Select
            additionLabel={null}
            selection
            fluid
            search
            name="current_comapny"
            placeholder="companies"
            multiple
            value={current_company || []}
            onChange={(event, { value }) => set_current_company(value)}
            options={AllCompany?.map((data, idx) => ({
              key: idx,
              text: data.current_company,
              value: data.current_company
            }))}
          />
          <Form.Select
            additionLabel={null}
            selection
            fluid
            search
            name="not_current_comapny"
            placeholder="exclude these companies"
            multiple
            value={not_current_company || []}
            onChange={(event, { value }) => set_not_current_company(value)}
            options={AllCompany?.map((data, idx) => ({
              key: idx,
              text: data.current_company,
              value: data.current_company
            }))}
          />
          <Form.Field>
            <DatePicker
              placeholder="created At"
              value={filterState?.applicant_date_creation}
              onChange={(e) => {
                setFilterState({
                  ...filterState,
                  applicant_date_creation: e,
                });
              }}
              format="YYYY-MM-DD"
              style={{ width: "100%", border: "none", background: "transparent", minHeight: "30px", height: "40px" }}
            />
          </Form.Field>
        </Form.Group>

        <Form.Group>
          <div style={{ width: "100%", display: "flex", justifyContent: "flex-end", marginRight: "10px" }}>
            <Button type="primary" htmlType="submit" style={{ minWidth: "12%", maxWidth: "200px", marginLeft: "20px" }}>
              Filter
            </Button>
            <Button
              style={{ minWidth: "12%", maxWidth: "200px", marginLeft: "20px" }}
              dashed
              type="button"
              onClick={resetFilters}
            >
              Reset All Filters
            </Button>
            <Button
              type="button"
              style={{ minWidth: "12%", maxWidth: "200px", marginLeft: "20px" }}
              onClick={onClose}
            >
              Hide Filters
            </Button>
          </div>
        </Form.Group>
      </Form>
    </div>
  );
};

export default CandidateFilter;