import React, { useState, useEffect } from "react";
// import backendimg from "../assets/backend-dev.png";
import {
  Button,
  Typography,
  message,
  Input,
  Form,
  Select,
  Spin,
  Row,
  Col,
  List,
  Drawer,
  Divider,
} from "antd";
import {
  FireOutlined,
  LaptopOutlined,
  BarChartOutlined,
  LeftOutlined, RightOutlined
} from "@ant-design/icons";
import { getAllJobs } from "../api/auth";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  selectMyJobs,
  ZyvkaMemberState,
  selectHotJobs,
  selectNonTechJobs,
  selectTechJobs,
} from "../features/ZyvkaMemberSlice";
import ViewJobDetails from "./ViewJobDetails";
import { useParams } from "react-router-dom";
import {
  fetchMyJobs,
  GetHotJobs,
  GetTechJobs,
  GetNonTechJobs,
  getSearchedJob,
  fetchAllPartnerAdmins,
  getAllZyvkaUsers,
} from "../api/ZyvkaMember";
import { filterJobs } from "../api/ZyvkaMember";
import { fetchAllSkills, getAllJobProfiles } from "../api/auth";
import { UserState } from "../features/userSlice";
import JobFilters from "./JobFilter";

const { Title } = Typography;
const { Option } = Select;
const { Search } = Input;
//Prints search value (only for testing purpose)
const onSearch = (value) => message.info(`Searched for: ${value}`);
//Search Bar ends

const exp = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
  22, 23, 24, 25, 26, 27, 28, 29, 30,
];

//Filter Buttons click action, this prints keys for now (check console also)

const statusMenu = [
  {
    label: "open",
    value: "open",
  },
  {
    label: "candidate offered",
    value: "candidate offered",
  },
  {
    label: "candidate accepted",
    value: "candidate accepted",
  },
  {
    label: "candidate joined",
    value: "candidate joined",
  },
  {
    label: "on hold",
    value: "on hold",
  },
  {
    label: "no more submissions",
    value: "no more submissions",
  },
  {
    label: "Closed",
    value: "closed",
  },
];

const AllJobs = () => {
  const [form] = Form.useForm();
  const [flag, setFlag] = useState(false);
  const [skillOption, setSkillOption] = useState([]);
  const zyvkaState = useSelector(ZyvkaMemberState);
  const userState = useSelector(UserState);
  const dispatch = useDispatch();
  const [filterOpen, setFilterOpen] = useState(false);
  const [allPartnerAdmins, setAllPartnerAdmins] = useState([]);
  const [allZyvkaUsers, setAllZyvkaUsers] = useState([]);

  // console.log(id);

  const showDrawer = () => {
    setOpenDrawer(true);
  };
  const onClose = () => {
    setOpenDrawer(false);
  };

  //More Details Drawer
  const [opendrawer, setOpenDrawer] = useState(false);
  //Drawer Ends

  const ChangeFlag = () => {
    setFlag((flag) => !flag);
  };

  const fetchHotJobs = async () => {
    try {
      await GetHotJobs({ myjobs: false, internalJobs: false, active_status: 1 }, dispatch);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchTechJobs = async () => {
    try {
      await GetTechJobs({ myjobs: false, internalJobs: false, active_status: 1 }, dispatch);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchNonTechJobs = async () => {
    try {
      await GetNonTechJobs({ myjobs: false, internalJobs: false, active_status: 1 }, dispatch);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchJobProfiles = async () => {
    try {
      await getAllJobProfiles(dispatch);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchSkills = async () => {
    try {
      await fetchAllSkills(dispatch, setSkillOption);
    } catch (error) {
      console.log(error);
    }
  };
  const GetAllPartnerAdmins = async () => {
    try {
      await fetchAllPartnerAdmins(dispatch, setAllPartnerAdmins);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchZyvkaUsers = async () => {
    await getAllZyvkaUsers(dispatch, setAllZyvkaUsers);
  };

  useEffect(() => {
    fetchHotJobs();
    fetchNonTechJobs();
    fetchTechJobs();
    fetchJobProfiles();
    GetAllPartnerAdmins();
    fetchZyvkaUsers();
    fetchSkills();
  }, [flag]);
  const onFinish = async (values) => {
    const act_stat = values.status === "closed" ? 4 : 1
    await filterJobs(
      {
        job_title: values.job_title,
        min_experience_req: values.min_experience_req,
        max_experience_req: values.max_experience_req,
        min_ctc: values.min_ctc,
        max_ctc: values.max_ctc,
        location: values.location,
        min_notice_period: values.min_notice_period,
        max_notice_period: values.max_notice_period,
        status: values.status,
        prefered_industry: values.prefered_industry,
        skills_req: values.skills_req,
        myjobs: false,
        internalJobs: false,
        active_status: act_stat,
        hotJob: values.hotJob === "yes" ? true : false,
        jobType: values.jobType
      },
      dispatch
    );
    setFilterOpen(true);
    setOpenDrawer(false);
  };
  const AllSkills = userState.gSkills;
  const AllProfiles = userState.gProfile;

  const onFormLayoutChange = ({ size }) => {
    setComponentSize(size);
  };
  const [componentSize, setComponentSize] = useState("default");

  const JobData = useSelector(selectMyJobs);
  const HotJobsData = useSelector(selectHotJobs);
  const TechJobsData = useSelector(selectTechJobs);
  const NonTechJobsData = useSelector(selectNonTechJobs);
  const onSearch = async (value) => {
    if (value === "") {
      setFilterOpen(false);
      setFlag((flag) => !flag);
    } else {
      const res = await getSearchedJob(
        { job: value, myjobs: false, internalJobs: false, active_status: 1 },
        dispatch
      );
      setFilterOpen(true);
    }
  };
  // NonTechJobsData.map((el) => el.id === "clh82lztk0001u1j0imbg980y" ? console.log(el.active_status, el.status) : null);
  // TechJobsData.map((el) => console.log(el.active_status, el.status));

  return (
    <div style={{ height: "100vh", overflow: "scroll" }}>
      <br></br>

      
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
          gap: "10px",
        }}
        className="searchbox"
      >
        <Button onClick={()=>setOpenDrawer(!opendrawer)}>Filter</Button>
        <Search
          placeholder="input search text"
          allowClear
          autoFocus
          autoSize
          enterButton="Search"
          onSearch={onSearch}
          onChange={(e) => {
            if (e.target.value === null) {
              setFlag((flag) => !flag);
              setFilterOpen(false);
            }
          }}
          style={{
            maxWidth: 400,
          }}
        />

{opendrawer &&(
         <JobFilters
         form={form}
         onFinish={onFinish}
         skillOptions={AllSkills}
         jobProfiles={AllProfiles}
         onReset={() => {
           form.resetFields();
           setFlag(flag => !flag);
           setFilterOpen(false);
         }}
         onClose={onClose}
         componentSize={componentSize}
         filterState={opendrawer}
         setFilterState={setOpenDrawer}
       />
      )}
      </div>

      {zyvkaState.isPending === true ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
          }}
        >
          <Spin size="large" />
        </div>
      ) : (
        <>
          {/* Hot Jobs Section         */}
          {filterOpen === false ? (
            <>
              {" "}
              <Divider orientation="left">
                <Title level={3} prefix={<FireOutlined />}>
                  <FireOutlined style={{ color: "orange" }} /> Hot Jobs
                </Title>
              </Divider>
              <div className="job-carousel" id="1" style={{ display: "flex", justifyItems: "center", position: "relative" }}>
                <Button style={{
                  position: "sticky",
                  top: '50%',
                  zIndex: 10,
                  left: 0,
                  width: "40px",
                  paddingLeft: "5px",
                  borderColor: "#6855f6",
                  boxShadow: "1px 2px 9px #B3A8FF"
                }} onClick={() => {
                  document.getElementById('1').scrollLeft -= 400;
                }}
                  // type="primary"
                  icon={<LeftOutlined style={{
                    color: "#6855f6", width: "40px",
                  }} />}
                />
                {/* <Carousel dots={{ className: "slider-dots" }} > */}
                {HotJobsData?.map((data, index) => {
                  return (
                    <div style={{ marginLeft: index === 0 ? '-25px' : '0px' }} >
                      {/* // <div style={{minHeight:"350px" ,minWidth:"270px", marginLeft:"10px", marginRight:"10px"}} key={data.id}> */}
                      <ViewJobDetails
                        data={data}
                        width={370}
                        ChangeFlag={ChangeFlag}
                        skills={skillOption}
                        AllPartnerAdmins={allPartnerAdmins}
                        AllZyvkaUsers={allZyvkaUsers}
                      />
                    </div>
                  );
                })}
                <Button style={{
                  position: "sticky",
                  width: "40px",
                  paddingLeft: "5px",
                  right: 20,
                  top: '50%',
                  zIndex: 10,
                  borderColor: "#6855f6",
                  boxShadow: "1px 2px 9px #B3A8FF"
                }} onClick={() => {
                  document.getElementById('1').scrollLeft += 400;
                }}
                  icon={<RightOutlined style={{
                    color: "#6855f6", width: "40px",
                  }} />}
                />
                {/* </Carousel> */}
              </div>
              <Divider orientation="left">
                <Title level={3}>
                  <LaptopOutlined style={{ color: "green" }} /> Tech Jobs
                </Title>
              </Divider>
              <div className="job-carousel" id="2" style={{ display: "flex", justifyItems: "center", position: "relative" }}>
                <Button style={{
                  position: "sticky",
                  top: '50%',
                  zIndex: 10,
                  left: 0,
                  width: "40px",
                  paddingLeft: "5px",
                  borderColor: "#6855f6",
                  boxShadow: "1px 2px 9px #B3A8FF"
                }} onClick={() => {
                  document.getElementById('2').scrollLeft -= 400;
                }}
                  // type="primary"
                  icon={<LeftOutlined style={{
                    color: "#6855f6", width: "40px",
                  }} />}
                />
                {TechJobsData?.map((data, index) => {
                  return (
                    <div style={{ marginLeft: index === 0 ? '-25px' : '0px' }}>
                      <ViewJobDetails
                        data={data}
                        width={370}
                        ChangeFlag={ChangeFlag}
                        skills={skillOption}
                        AllPartnerAdmins={allPartnerAdmins}
                        AllZyvkaUsers={allZyvkaUsers}
                      />
                    </div>
                  );
                })}
                <Button style={{
                  position: "sticky",
                  width: "40px",
                  paddingLeft: "5px",
                  right: 20,
                  top: '50%',
                  zIndex: 10,
                  borderColor: "#6855f6",
                  boxShadow: "1px 2px 9px #B3A8FF"
                }} onClick={() => {
                  document.getElementById('2').scrollLeft += 400;
                }}
                  icon={<RightOutlined style={{
                    color: "#6855f6", width: "40px",
                  }} />}
                />
              </div>
              <Divider orientation="left">
                <Title level={3}>
                  <BarChartOutlined style={{ color: "blue" }} /> Non-Tech Jobs
                </Title>
              </Divider>
              <div className="job-carousel" id="3" style={{ display: "flex", justifyItems: "center", position: "relative" }}>
                <Button style={{
                  position: "sticky",
                  top: '50%',
                  zIndex: 10,
                  left: 0,
                  width: "40px",
                  paddingLeft: "5px",
                  borderColor: "#6855f6",
                  boxShadow: "1px 2px 9px #B3A8FF"
                }} onClick={() => {
                  document.getElementById('3').scrollLeft -= 400;
                }}
                  // type="primary"
                  icon={<LeftOutlined style={{
                    color: "#6855f6", width: "40px",
                  }} />}
                />
                {NonTechJobsData?.map((data, index) => {
                  return (
                    <div style={{ marginLeft: index === 0 ? '-25px' : '0px' }}>
                      {/* // <div style={{minHeight:"350px" ,minWidth:"270px", marginLeft:"10px", marginRight:"10px"}} key={data.id}> */}
                      <ViewJobDetails
                        data={data}
                        width={350}
                        ChangeFlag={ChangeFlag}
                        skills={skillOption}
                        AllPartnerAdmins={allPartnerAdmins}
                        AllZyvkaUsers={allZyvkaUsers}
                      />
                    </div>
                  );
                })}
                <Button style={{
                  position: "sticky",
                  width: "40px",
                  paddingLeft: "5px",
                  right: 20,
                  top: '50%',
                  zIndex: 10,
                  borderColor: "#6855f6",
                  boxShadow: "1px 2px 9px #B3A8FF"
                }} onClick={() => {
                  document.getElementById('3').scrollLeft += 400;
                }}
                  icon={<RightOutlined style={{
                    color: "#6855f6", width: "40px",
                  }} />}
                />
              </div>
            </>
          ) : (
            <Row>
              <Col flex={1.5}></Col>
              <Col flex={7}>
                <List
                  grid={{
                    gutter: 16,
                    xs: 1,
                    sm: 1,
                    md: 2,
                    lg: 3,
                    xl: 3,
                    xxl: 3,
                  }}
                  dataSource={JobData}
                  renderItem={(item) => (
                    <List.Item>
                      <ViewJobDetails
                        data={item}
                        ChangeFlag={ChangeFlag}
                        skills={skillOption}
                        AllPartnerAdmins={allPartnerAdmins}
                        AllZyvkaUsers={allZyvkaUsers}
                      />
                      {/* <JobCard data={item} /> */}

                      {/* <Card hoverable bordered={false} title={item.title} style={{borderRadius:"5px"}}>{item.description}</Card> */}
                    </List.Item>
                  )}
                />
              </Col>
              <Col flex={1.5}></Col>
            </Row>
          )}



          {/* All Jobs section */}
        </>
      )}
    </div>
  );
};

export default AllJobs;
