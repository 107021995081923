

import React, { useEffect, useState } from 'react';
import SceytChatClient from "sceyt-chat";
import { v4 as uuidv4 } from 'uuid';
import {
  ChannelDetails,
  ChannelList,
  Chat,
  ChatHeader,
  MessageList,
  MessagesScrollToBottomButton,
  SceytChat,
  SendMessage,
} from 'sceyt-chat-react-uikit';
import lightModeIcon from './assets/svg/lightModeIcon.svg';
import darkModeIcon from './assets/svg/darkModeIcon.svg';
import sceytIcon from './assets/img/sceyt_rounded.png';
import './ChatApp.css';
import { genToken } from "./api";
import { SceytContext } from './sceytContext';
import ChannelCustomList from "./ChannelCustomList";
import CreateChannelButton from "./CreateChannel";
import useDidUpdate from "./hooks/useDidUpdate";
import useMobileView from "./hooks/useMobileView";
import CustomMessageItem from './CustomMessageItem';
import { UserState } from "../../features/userSlice"
import { useSelector } from 'react-redux';
import axios from 'axios';
import { useLocation } from 'react-router-dom';

const MOBILE_ACTIVE_VIEW = {
  CHANNELS: 'channels',
  CHAT: 'chat'
};

function ChatComponent() {
    const location = useLocation();
        const user = location?.state?.user;
        console.log("dsfsg",user);
        
   
        const id = user?.userID;
        const token = user?.chatToken;
  
  const [client, setClient] = useState(null);
  const [clientState, setClientState] = useState('');
  const [chatToken, setChatToken] = useState(null);
  const [theme, setTheme] = useState('light');
  const [userId, setUserId] = useState(id ||'');
  const [rolesMap, setRolesMap] = useState(null);
  const isMobile = useMobileView();
  const [mobileActiveView, setMobileActiveView] = useState(MOBILE_ACTIVE_VIEW.CHANNELS);

  const activeChannelIsChanged = (channel) => {
    if(channel && channel.id) {
      setMobileActiveView(MOBILE_ACTIVE_VIEW.CHAT);
    }
  };

  const handleBackToChannels = () => {
    setMobileActiveView(MOBILE_ACTIVE_VIEW.CHANNELS);
  };

  function getRandomNumber() {
    return Math.floor(Math.random() * 20);
  }

  const getToken = async() => {
    const data = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/scyetChat/chatToken/${userId}`,{withCredentials:true});
    // genToken(userId).then(async (tokenData) => {
      // console.log('token ... ',data.data);
    //   const { token } = await tokenData.json();
      setChatToken(data.data.chat_token);
  };

  const connectClient =  (token) => {
    // const sceytClient = new SceytChatClient(
    //   process.env.REACT_APP_SCEYT_API_URL,
    //   process.env.REACT_APP_SCEYT_APP_ID,
    //   uuidv4()
    // );
    const sceytClient = new SceytChatClient('https://us-ohio-api.sceyt.com', '93k2e1v42l', uuidv4());

    

    sceytClient.setLogLevel('trace');
     
    const listener = new sceytClient.ConnectionListener();
       
    
    listener.onConnectionStateChanged = async (status) => {
      setClientState(status);
      if (status === 'Failed') {
        await getToken();
      } else if (status === 'Connected') {
        console.log('client user.. .. ', sceytClient.user);
        try {
          sceytClient.setPresence('online');
        } catch (e) {
        //   console.log('error on setPresence. .. ', e);
        }
      }
    };

    listener.onTokenWillExpire = async () => {
      getToken();
    };

    listener.onTokenExpired = async () => {
      if (clientState === 'Connected') {
        getToken();
      } else {
        await getToken();
      }
    };

    sceytClient.addConnectionListener('listener_id', listener);

    sceytClient.connect(token)
      .then(() => {
        setClient(sceytClient);
       
        if (typeof window !== 'undefined' && window != null && window.addEventListener != null) {
          window.addEventListener('offline', (e) => onlineStatusChanged(e, sceytClient));
          window.addEventListener('online', (e) => onlineStatusChanged(e, sceytClient));
        }
      })
      .catch((e) => {
        const date = new Date();
        console.error(`${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}:${date.getMilliseconds()} : Error on connect ... `, e);
        getToken();
      });
     
  };

  const onlineStatusChanged = (event, client) => {
    const date = new Date();
    console.info(`${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}:${date.getMilliseconds()} : Online status changed : `, event.type);
    if (event.type === 'online') {
      setTimeout(() => {
        if (client && (!client.accessToken || client.connectionState === 'Disconnected')) {
          getToken();
        }
      }, 1000);
    }
  };

  useDidUpdate(() => {
    if (chatToken) {
      if (client && clientState === 'Connected') {
        client.updateToken(chatToken);
      } else {
        if (client && chatToken) {
          client.connect(chatToken)
            .then(() => {
              setClientState('Connected');
            })
            .catch((e) => {
              const date = new Date();
              console.error(`${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}:${date.getMilliseconds()} : Error on connect after updating the token ... `, e);
              if (e.code === 10005 && client && client.connectionState === 'Connected') {
                setClientState('Connected');
              } else {
                getToken();
              }
            });
        } else {
          connectClient(chatToken);
        }
      }
    }
  }, [chatToken]);

  // useEffect(() => {
  //   if (!userId) {
  //     setUserId(guestsUsersList[getRandomNumber()]);
  //   }
  // }, []);

  useEffect(() => {
    if (!chatToken && userId) {
      getToken();
    }
  }, [userId]);

  useEffect(() => {
    if (client && clientState === 'Connected') {
      client
        .getRoles()
        .then((roles) => {
          const rolesMap = {};
          roles.forEach((role) => {
            rolesMap[role.name] = role.permissions;
          });
          setRolesMap(rolesMap);
        })
        .catch((e) => {
          console.log('error on get roles', e);
        });
    }
  }, [client]);

  useEffect(() => {
    const applyCustomStyles = () => {
      const chatContainer = document.querySelector('#sceyt_chat_container');
      const jvLaETElement = document.querySelector('.sc-ktPPKK.jvLaET');
      const HnrNqElement = document.querySelector('.HnrNq');
      const bOjauxElement = document.querySelector('.bOjaux');
      
      if (chatContainer) {
        chatContainer.style.minWidth = window.innerWidth > 760 ? '1000px' : '100%';
      }
      
      if (jvLaETElement) {
        jvLaETElement.style.backgroundColor = 'rgba(104, 85, 246, 0.06)';
      }
      
      if (HnrNqElement) {
        HnrNqElement.style.color = 'black';
      }
      
      if (bOjauxElement) {
        bOjauxElement.style.backgroundColor = 'rgba(104, 85, 246, 0.23)';
      }
    };

    // Initial style application
    const styleCheckInterval = setInterval(applyCustomStyles, 100);

    // Handle resize events
    window.addEventListener('resize', applyCustomStyles);

    // Cleanup
    return () => {
      clearInterval(styleCheckInterval);
      window.removeEventListener('resize', applyCustomStyles);
    };
  }, []);

  // // Effect for modal control
  // useEffect(() => {
  //   const hideModal = () => {
  //     const addMemberModal = document.querySelector('.sc-guJBdh.fBEcCy');
  //     if (addMemberModal) {
  //       addMemberModal.style.display = 'none';
  //     }
  //   };

  //   // Initial modal check
  //   const modalCheckInterval = setInterval(hideModal, 100);

  //   // Watch for modal appearing
  //   const observer = new MutationObserver(() => {
  //     hideModal();
  //   });

  //   // Start observing
  //   observer.observe(document.body, {
  //     childList: true,
  //     subtree: true
  //   });

  //   // Cleanup
  //   return () => {
  //     clearInterval(modalCheckInterval);
  //     observer.disconnect();
  //   };
  // }, []);

  return (
    <div className="main">
      <SceytContext.Provider value={{ client, theme }}>
        <div className='messenger_demo_wrapper'>
          {/* <div className='theme_switcher'>
            <div 
              className={`theme_switcher_item light_mode ${theme === 'light' ? 'active' : ''}`}
              onClick={() => setTheme('light')}
            >
              <img src={lightModeIcon} alt="light mode" />
              <span>Light Mode</span>
            </div>
            <div 
              className={`theme_switcher_item dark_mode ${theme === 'dark' ? 'active' : ''}`}
              onClick={() => setTheme('dark')}
            >
              <img src={darkModeIcon} alt="dark mode" />
              <span>Dark Mode</span>
            </div>
          </div>
          <div className='messenger_demo_header'>
            <span className='red_circle'></span>
            <span className='orange_circle'></span>
            <span className='green_circle'></span>
          </div> */}
          <div className='sceyt_chat_wrapper'>
            {client ? (
              <SceytChat
                themeMode={theme}
                theme={{
                  colors: {
                    accent: {
                      dark: '#6B72FF',
                      light: '#5159F6'
                    },
                    textPrimary: {
                      dark: '#ffffffcc',
                      light: '#111539'
                    }
                  },
                }}
                showNotifications={false}
                customColors={{ primaryColor: '#5159F6' }}
                client={client}
              >
                {(!isMobile || mobileActiveView === MOBILE_ACTIVE_VIEW.CHANNELS) && (
                  <ChannelList
                    List={(props) => <ChannelCustomList {...props} userRole={user?.type} activeChannelIsChanged={activeChannelIsChanged} />}
                    CreateChannel={<CreateChannelButton userRole={user?.type} userId={id} />}
                    backgroundColor={'#1B1C25'}
                    searchInputBackgroundColor={'#25262E'}
                    selectedChannelBackground={'#25262E'}
                    searchInputTextColor={'#ffffffcc'}
                    ChannelsTitle={<div className={`channels_title ${theme} dark`}> Chats</div>}
                  />
                )}
                {(!isMobile || mobileActiveView === MOBILE_ACTIVE_VIEW.CHAT) && (
                  <Chat className='custom_chat'>
                    <ChatHeader mobileBackButtonClicked={handleBackToChannels} />
                    <MessageList
                      reactionsContainerBackground={'inherit'}
                      reactionsContainerBoxShadow={'inherit'}
                      reactionsContainerPadding={'0 0 4px'}
                      reactionItemPadding={'5px 10px'}
                      ownMessageOnRightSide={false}
                      showSenderNameOnOwnMessages
                      showSenderNameOnDirectChannel
                      showOwnAvatar
                      incomingMessageBackground='inherit'
                      ownMessageBackground='inherit'
                      showMessageTimeAndStatusOnlyOnHover
                      reportMessage={false}
                      replyMessageInThread={false}
                      CustomMessageItem={(props) => (
                        <CustomMessageItem {...props} client={client} rolesMap={rolesMap} />
                      )}
                      fileAttachmentsBoxWidth={isMobile ? 220 : undefined}
                      imageAttachmentMaxWidth={isMobile ? 220 : undefined}
                      imageAttachmentMaxHeight={isMobile ? 200 : undefined}
                      videoAttachmentMaxWidth={isMobile ? 220 : undefined}
                      videoAttachmentMaxHeight={isMobile ? 200 : undefined}
                    />
                    <MessagesScrollToBottomButton bottomPosition={65} rightPosition={4} />
                    <SendMessage
                      margin='30px 0 10px -1px'
                      inputPaddings='6px 0'
                      backgroundColor='inherit'
                      emojiIcoOrder={1}
                      inputCustomClassname='sceyt_send_message_input'
                      CustomSendMessageButton={<div>Send</div>}
                    />
                  </Chat>
                )}
                <ChannelDetails size='small' avatarAndNameDirection='column'  showDeleteChannel />
              </SceytChat>
            ) : (
              <div className='messenger_loading'>
                <img src={sceytIcon} alt="sceyt logo" />
              </div>
            )}
          </div>
        </div>
      </SceytContext.Provider>
    </div>
  );
}

export default ChatComponent;