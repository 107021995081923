import React, { useEffect, useState, useContext } from 'react';
import { SceytContext } from "../sceytContext";
import { Avatar } from "sceyt-chat-react-uikit";

function ChannelCustomList({ 
    channels, 
    searchedChannels, 
    loadMoreChannels, 
    setSelectedChannel, 
    selectedChannel, 
    searchValue, 
    activeChannelIsChanged,
    userRole
}) {
    const { client: sceytClient, setActiveChannel } = useContext(SceytContext);
    const role = (userRole === "partner" || userRole === "partnerAdmin") ? "partner" : "zyvkaUser";
    const [channelListWithGroups, setChannelListWithGroups] = useState([]);
    const [user, setUser] = useState(null);

    // useEffect(() => {
    //     if (channels && channels.length > 0 && !sceytClient.activeChannelId) {
    //         const mostRecentChannel = channels.reduce((recent, current) => {
    //             const recentTime = recent.lastMessage ? recent.lastMessage.createdAt : recent.createdAt;
    //             const currentTime = current.lastMessage ? current.lastMessage.createdAt : current.createdAt;
    //             return currentTime > recentTime ? current : recent;
    //         }, channels[0]);
            
    //         handleChangeActiveChannel(mostRecentChannel);
    //     }
    // }, [channels, sceytClient.activeChannelId]);

    const handleScroll = (e) => {
        if (e.target.scrollTop >= (e.target.scrollHeight - e.target.offsetHeight) - 200) {
            loadMoreChannels();
        }
    };

    const makeUserName = (user) => {
        if (!user) return '';
        return user.firstName ? `${user.firstName} ${user.lastName}` : user.id;
    };

    const handleCreateChannelsGroupList = () => {
        const channelsGroupList = [];
        const channelGroupMap = { group: [], direct: [] };
        const groups = [];
        const directs = [];

        channels.forEach(channel => {
            const channelGroupType = channel.type === 'direct' ? 'direct' : 'group';
            if (channelGroupType === 'direct') {
                directs.push(channel);
            } else {
                groups.push(channel);
            }
        });

        channelGroupMap.group = groups;
        channelGroupMap.direct = directs;

        Object.keys(channelGroupMap).forEach(key => {
            const groupName = key === 'direct' ? 'DIRECT MESSAGES' : 'GROUPS';
            channelsGroupList.push({ 
                groupName, 
                channelList: channelGroupMap[key].sort(
                    (a, b) =>
                        (b.lastMessage ? b.lastMessage.createdAt : b.createdAt) -
                        (a.lastMessage ? a.lastMessage.createdAt : a.createdAt)
                )
            });
        });

        setChannelListWithGroups(channelsGroupList);
    };

    const handleChangeActiveChannel = (channel) => {
        if (!channel) return;
        
        setActiveChannel(channel);
        
        if (setSelectedChannel) {
            setSelectedChannel(channel);
        }

        if (activeChannelIsChanged) {
            setTimeout(() => {
                activeChannelIsChanged(channel);
            }, 200);
        }
    };

    const isZyvkaUser = (member) => {
        try {
            const metadata = typeof member.metadata === 'string' 
                ? JSON.parse(member.metadata)
                : member.metadata;
            return !metadata?.role || (metadata.role !== 'partnerAdmin' && metadata.role !== 'partner');
        } catch (e) {
            return true;
        }
    };

    const getChannelDisplayInfo = (channel, directChannelUser) => {
        const isDirectChannel = channel.type === 'direct';

        if (role === "partner") {
            const zyvkaUsers = channel.members.filter(isZyvkaUser);
            
            if (zyvkaUsers.length > 0) {
                return {
                    name: isDirectChannel && directChannelUser 
                        ? makeUserName(directChannelUser) 
                        : `${channel.subject}`,
                    avatarUrl: "https://i.ibb.co/vZs5YmC/logozyvk.png",
                    avatarName: (directChannelUser || {}).id || ''
                };
            }

            return {
                name: isDirectChannel && directChannelUser 
                    ? makeUserName(directChannelUser) 
                    : `${channel.subject}`,
                avatarUrl: isDirectChannel ? (directChannelUser || {}).avatarUrl : null,
                avatarName: (directChannelUser || {}).id || ''
            };
        }

        return {
            name: isDirectChannel && directChannelUser 
                ? makeUserName(directChannelUser) 
                : `${channel.subject}`,
            avatarUrl: isDirectChannel ? (directChannelUser || {}).avatarUrl : null,
            avatarName: (directChannelUser || {}).id || ''
        };
    };

    useEffect(() => {
        if (sceytClient) {
            setUser(sceytClient.user);
        }
    }, [sceytClient]);

    useEffect(() => {
        if (channels) {
            handleCreateChannelsGroupList();
        }
    }, [channels]);

    useEffect(() => {
        if (!searchValue) {
            handleCreateChannelsGroupList();
        }
    }, [searchValue]);

    useEffect(() => {
        if (searchedChannels && searchValue) {
            const channelsGroupList = [];
            const directChannels = [];
            const groupChannels = [];

            for (let key in searchedChannels) {
                if (searchedChannels[key].length) {
                    searchedChannels[key].forEach(channel => {
                        if (channel.type === 'direct') {
                            directChannels.push(channel);
                        } else {
                            groupChannels.push(channel);
                        }
                    });
                }
            }

            if (groupChannels.length > 0) {
                channelsGroupList.push({
                    groupName: 'GROUPS',
                    channelList: groupChannels.sort(
                        (a, b) =>
                            (b.lastMessage ? b.lastMessage.createdAt : b.createdAt) -
                            (a.lastMessage ? a.lastMessage.createdAt : a.createdAt)
                    )
                });
            }

            if (directChannels.length > 0) {
                channelsGroupList.push({
                    groupName: 'DIRECT MESSAGES',
                    channelList: directChannels.sort(
                        (a, b) =>
                            (b.lastMessage ? b.lastMessage.createdAt : b.createdAt) -
                            (a.lastMessage ? a.lastMessage.createdAt : a.createdAt)
                    )
                });
            }

            setChannelListWithGroups(channelsGroupList);
        }
    }, [searchedChannels, searchValue]);

    if (!channels || channels.length === 0) {
        return null;
    }

    return (
        <div className={`channels_custom_list ${sceytClient.theme}`} onScroll={handleScroll}>
            {channelListWithGroups.map(channelGroup => (
                <div key={channelGroup.groupName}>
                    {channelGroup.channelList && channelGroup.channelList.length > 0 && (
                        <h3 className='channels_group_name'>{channelGroup.groupName}</h3>
                    )}
                    {channelGroup.channelList && channelGroup.channelList.map(channel => {
                        const isDirectChannel = channel.type === 'direct';
                        const directChannelUser = isDirectChannel && channel.members.find(member => member.id !== (user || {}).id);
                        const displayInfo = getChannelDisplayInfo(channel, directChannelUser);
                        
                        return (
                            <div
                                key={channel.id}
                                onClick={() => handleChangeActiveChannel(channel)}
                                className={`custom_channel_item ${
                                    (selectedChannel && selectedChannel.id === channel.id) || 
                                    channel.id === sceytClient.activeChannelId ? 'active' : ''
                                }`}
                            >
                                <Avatar
                                    name={displayInfo.avatarName}
                                    size={32}
                                    image={displayInfo.avatarUrl}
                                    setDefaultAvatar
                                />
                                <h4 className="channel_name withAvatar">
                                    {displayInfo.name}
                                </h4>
                                {!!(channel.newMessageCount && channel.newMessageCount > 0) && (
                                    <span className='channel_unread_count'>
                                        {channel.newMessageCount < 100 ? channel.newMessageCount : '+99'}
                                    </span>
                                )}
                            </div>
                        );
                    })}
                </div>
            ))}
        </div>
    );
}

export default ChannelCustomList;