import { useEffect, useCallback, useRef } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { setUnreadCount } from '../features/chatSlice';

const useUnreadMessages = (userId, interval = 30000) => {
  const dispatch = useDispatch();
  const timeoutRef = useRef(null);

  const fetchUnreadMessages = useCallback(async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/scyetChat/getUnreadMessages/${userId}`,
        { withCredentials: true }
      );
      
      if (response.statusText === "OK") {
        dispatch(setUnreadCount(response.data.totalUnreadMessages));
      }
    } catch (err) {
      console.error('Error fetching unread messages:', err);
    }

    // Schedule next fetch
    timeoutRef.current = setTimeout(fetchUnreadMessages, interval);
  }, [userId, interval, dispatch]);

  useEffect(() => {
    // Initial fetch
    fetchUnreadMessages();

    // Cleanup function
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [fetchUnreadMessages]);
};

export default useUnreadMessages;