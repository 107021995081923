import React from 'react';
import { Form } from "semantic-ui-react";
import { DatePicker, Button } from "antd";

const ViewApplicantFilter = ({
  filterState,
  setFilterState,
  current_companies,
  setCurrent_companies,
  notIn_current_companies,
  setNotIn_current_companies,
  keySkills,
  setKeySkills,
  onFinish,
  resetFilters,
  onClose,
  AllSkills,
  AllCompany,
  roundOptions,
  isPartner,
  compName
}) => {

  console.log(AllCompany);
  const containerStyle = {
    width: '100%',
    padding: '16px'
  };

  const formGroupStyle = {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
    gap: '10px',
  
  };

  const inputStyle = {
    width: '100%',
    minHeight: '40px',
    padding: '8px',
    boxSizing: 'border-box'
  };

  const buttonGroupStyle = {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '12px',
    marginTop: '10px'
  };

  return (
    <div style={containerStyle}>
      <Form onSubmit={onFinish}>
        <div style={formGroupStyle}>
          <Form.Input
            fluid
            name="min_notice_period"
            placeholder="Min Notice Period (days)"
            type="number"
            value={filterState?.min_notice_period}
            onChange={(e) => {
              setFilterState({
                ...filterState,
                min_notice_period: e.target.value,
              });
            }}
            style={inputStyle}
          />
          <Form.Input
            fluid
            name="max_notice_period"
            placeholder="Max Notice Period (days)"
            type="number"
            value={filterState?.max_notice_period}
            onChange={(e) => {
              setFilterState({
                ...filterState,
                max_notice_period: e.target.value,
              });
            }}
            style={inputStyle}
          />
          <Form.Input
            name="min_current_salary"
            placeholder="Min Current Salary (In Lakh)"
            type="number"
            value={filterState?.min_current_salary}
            onChange={(e) => {
              setFilterState({
                ...filterState,
                min_current_salary: e.target.value,
              });
            }}
            style={inputStyle}
          />
          <Form.Input
            name="max_current_salary"
            placeholder="Max Current Salary (In Lakh)"
            type="number"
            value={filterState?.max_current_salary}
            onChange={(e) => {
              setFilterState({
                ...filterState,
                max_current_salary: e.target.value,
              });
            }}
            style={inputStyle}
          />
        </div>

        <div style={formGroupStyle}>
          <Form.Input
            name="min_expected_salary"
            placeholder="Min Expected Salary (In Lakh)"
            type="number"
            value={filterState?.min_expected_salary}
            onChange={(e) => {
              setFilterState({
                ...filterState,
                min_expected_salary: e.target.value,
              });
            }}
            style={inputStyle}
          />
          <Form.Input
            name="max_expected_salary"
            placeholder="Max Expected Salary (In Lakh)"
            type="number"
            value={filterState?.max_expected_salary}
            onChange={(e) => {
              setFilterState({
                ...filterState,
                max_expected_salary: e.target.value,
              });
            }}
            style={inputStyle}
          />
          <Form.Input
            name="min_total_experience"
            placeholder="Min Total Experience (years)"
            type="number"
            value={filterState?.min_total_experience}
            onChange={(e) => {
              setFilterState({
                ...filterState,
                min_total_experience: e.target.value,
              });
            }}
            style={inputStyle}
          />
          <Form.Input
            name="max_total_experience"
            placeholder="Max Total Experience (years)"
            type="number"
            value={filterState?.max_total_experience}
            onChange={(e) => {
              setFilterState({
                ...filterState,
                max_total_experience: e.target.value,
              });
            }}
            style={inputStyle}
          />
        </div>

        <div style={formGroupStyle}>
          <Form.Input
            name="current_designation"
            placeholder="Current Designation"
            value={filterState?.current_designation}
            onChange={(e) => {
              setFilterState({
                ...filterState,
                current_designation: e.target.value,
              });
            }}
            style={inputStyle}
          />
          <Form.Input
            name="location"
            placeholder="Location"
            value={filterState?.location}
            onChange={(e) => {
              setFilterState({
                ...filterState,
                location: e.target.value,
              });
            }}
            style={inputStyle}
          />
          <Form.Select
            name="salary_type"
            placeholder="Salary Currency"
            value={filterState?.salary_type}
            onChange={(e, { value }) => {
              setFilterState({
                ...filterState,
                salary_type: value,
              });
            }}
            options={[
              { key: 1, text: "$ USD", value: "USD" },
              { key: 2, text: "₹ INR", value: "INR" },
              { key: 3, text: "€ EURO", value: "EURO" },
            ]}
            style={inputStyle}
          />
          <Form.Select
            name="current_job_status"
            placeholder="Current Job Status"
            value={filterState?.current_job_status}
            onChange={(e, { value }) => {
              setFilterState({
                ...filterState,
                current_job_status: value,
              });
            }}
            options={roundOptions?.map((data, idx) => ({
              key: idx,
              value: data,
              text: data,
            }))}
            style={inputStyle}
          />
        </div>

        <div style={formGroupStyle}>
          <Form.Select
            multiple
            search
            selection
            name="key_skills"
            placeholder="Key Skills"
            value={keySkills || []}
            onChange={(e, { value }) => setKeySkills(value)}
            options={AllSkills?.map((data, idx) => ({
              key: idx,
              text: data.skill,
              value: data.skill,
            }))}
            style={inputStyle}
          />
          <Form.Select
            multiple
            search
            selection
            name="current_companies"
            placeholder="Current Companies"
            value={current_companies || []}
            onChange={(e, { value }) => setCurrent_companies(value)}
            options={AllCompany?.map((data, idx) => ({
              key: idx,
              text: data.current_company,
              value: data.current_company,
            }))}
            style={inputStyle}
          />
          <Form.Select
            multiple
            search
            selection
            name="notIn_current_companies"
            placeholder="Exclude Companies"
            value={notIn_current_companies || []}
            onChange={(e, { value }) => setNotIn_current_companies(value)}
            options={AllCompany?.map((data, idx) => ({
              key: idx,
              text: data.current_company,
              value: data.current_company,
            }))}
            style={inputStyle}
          />
          <Form.Checkbox
            label="CTC Negotiable"
            checked={filterState?.ctc_negotiable}
            onChange={(e, { checked }) => {
              setFilterState({
                ...filterState,
                ctc_negotiable: checked,
              });
            }}
          />
        </div>

        <div style={formGroupStyle}>
          {/* <Form.Checkbox
            label="Is Rockstar"
            checked={filterState?.isRockstar}
            onChange={(e, { checked }) => {
              setFilterState({
                ...filterState,
                isRockstar: checked,
              });
            }}
          /> */}
          {!isPartner && (
            <Form.Select
              search
              selection
              name="partner_company_name"
              placeholder="Partner Company"
              value={filterState?.partner_company_name}
              onChange={(e, { value }) => {
                setFilterState({
                  ...filterState,
                  partner_company_name: value,
                });
              }}
              options={compName?.map((data, idx) => ({
                key: idx,
                value: data.company_name,
                text: data.company_name,
              }))}
              style={inputStyle}
            />
          )}
          <Form.Input
            name="candidateSource"
            placeholder="Candidate Source"
            value={filterState?.candidateSource}
            onChange={(e) => {
              setFilterState({
                ...filterState,
                candidateSource: e.target.value,
              });
            }}
            style={inputStyle}
          />
        <Form.Field>
          <DatePicker
            placeholder="Created At"
            value={filterState?.applicant_date_creation}
            onChange={(date) => {
              setFilterState({
                ...filterState,
                applicant_date_creation: date,
              });
            }}
            format="YYYY-MM-DD"
            style={{ ...inputStyle, width: '100%' }}
          />
          </Form.Field>
          <Form.Input
            name="searchValue"
            placeholder="Search"
            value={filterState?.searchValue}
            onChange={(e) => {
              setFilterState({
                ...filterState,
                searchValue: e.target.value,
              });
            }}
            style={inputStyle}
          />
        </div>
        <div style={buttonGroupStyle}>
          <Button type="primary" htmlType="submit" style={{ minWidth: '120px' }}>
            Filter
          </Button>
          <Button onClick={resetFilters} style={{ minWidth: '120px' }}>
            Reset All Filters
          </Button>
          <Button onClick={onClose} style={{ minWidth: '120px' }}>
            Hide Filters
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default ViewApplicantFilter;