import React, { useState, useEffect } from "react";
// import backendimg from "../assets/backend-dev.png";
import {
  Button,
  Typography,
  message,
  Input,
  Form,
  Select,
  Spin,
  Row,
  Col,
  List,
  Drawer,
  Divider,
} from "antd";
import {
  FireOutlined,
  LaptopOutlined,
  BarChartOutlined,
  LeftOutlined,
  RightOutlined
} from "@ant-design/icons";
import { getAllJobs } from "../api/auth";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  selectMyJobs,
  ZyvkaMemberState,
  selectHotJobs,
  selectNonTechJobs,
  selectTechJobs,
} from "../features/ZyvkaMemberSlice";
import ViewJobDetails from "./ViewJobDetails";
import {
  fetchMyJobs,
  GetHotJobs,
  GetTechJobs,
  GetNonTechJobs,
  getSearchedJob,
} from "../api/ZyvkaMember";
import { filterJobs } from "../api/ZyvkaMember";
import { fetchAllSkills, getAllJobProfiles } from "../api/auth";
import { UserState } from "../features/userSlice";
import { useParams, useSearchParams } from "react-router-dom";
import JobFilters from "./JobFilter";
const { Title } = Typography;
const { Option } = Select;
const { Search } = Input;
//Prints search value (only for testing purpose)
const onSearch = (value) => message.info(`Searched for: ${value}`);
//Search Bar ends

const exp = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
  22, 23, 24, 25, 26, 27, 28, 29, 30,
];

const statusMenu = [
  {
    label: "open",
    value: "open",
  },
  {
    label: "Candidate Offered",
    value: "candidate offered",
  },
  {
    label: "Candidate Accepted",
    value: "candidate accepted",
  },
  {
    label: "Closed",
    value: "closed",
  },
];

const InternalJobs = () => {
  const [form] = Form.useForm();
  const [skillOption, setSkillOption] = useState([]);

  const [flag, setFlag] = useState(false);
  const zyvkaState = useSelector(ZyvkaMemberState);
  const userState = useSelector(UserState);
  const dispatch = useDispatch();
  const [filterOpen, setFilterOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const showDrawer = () => {
    setOpenDrawer(true);
    // setOpen((open) => !open);
  };
  const onClose = () => {
    setOpenDrawer(false);
    // setOpen((open) => !open);
  };

  //More Details Drawer
  const [opendrawer, setOpenDrawer] = useState(false);
  //Drawer Ends

  const ChangeFlag = () => {
    setFlag((flag) => !flag);
  };
  const [activeStatus, setActiveStatus] = useState(
    searchParams.get("type")
      ? searchParams.get("type") === "archive"
        ? 3
        : 4
      : 1
  );
  const fetchHotJobs = async () => {
    try {
      await GetHotJobs({ myjobs: false, active_status: activeStatus, internalJobs: true }, dispatch);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchTechJobs = async () => {
    try {
      await GetTechJobs({ myjobs: false, active_status: activeStatus, internalJobs: true }, dispatch);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchNonTechJobs = async () => {
    try {
      await GetNonTechJobs({ myjobs: false, active_status: activeStatus, internalJobs: true }, dispatch);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchJobProfiles = async () => {
    try {
      await getAllJobProfiles(dispatch);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchSkills = async () => {
    try {
      await fetchAllSkills(dispatch, setSkillOption);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // fetchJobs(id);
    fetchHotJobs();
    fetchNonTechJobs();
    fetchTechJobs();
    fetchJobProfiles();
    fetchSkills();
  }, [flag]);
  const onFinish = async (values) => {
    const act_stat = values.status === "closed" ? 4 : 1
    console.log(values)
    await filterJobs(
      {
        job_title: values.job_title,
        min_experience_req: values.min_experience_req,
        max_experience_req: values.max_experience_req,
        min_ctc: values.min_ctc,
        max_ctc: values.max_ctc,
        location: values.location,
        min_notice_period: values.min_notice_period,
        max_notice_period: values.max_notice_period,
        status: values.status,
        prefered_industry: values.prefered_industry,
        skills_req: values.skills_req,
        myjobs: false,
        internalJobs: true,
        active_status: act_stat,
        hotJob: values.hotJob === "yes" ? true : false,
        jobType: values.jobType
      },
      dispatch
    );
    setFilterOpen(true);
  };
  const AllSkills = userState.gSkills;
  const AllProfiles = userState.gProfile;

  const onFormLayoutChange = ({ size }) => {
    setComponentSize(size);
  };
  const [componentSize, setComponentSize] = useState("default");

  const JobData = useSelector(selectMyJobs);
  const HotJobsData = useSelector(selectHotJobs);
  const TechJobsData = useSelector(selectTechJobs);
  const NonTechJobsData = useSelector(selectNonTechJobs);
  const onSearch = async (value) => {
    if (value === "") {
      setFilterOpen(false);
      setFlag((flag) => !flag);
    } else {
      await getSearchedJob(
        { job: value, myjobs: false, internalJobs: true, active_status: activeStatus },
        dispatch
      );
      setFilterOpen(true);
    }
  };
  const onChange = (e, { name }) => {
    console.log(name);
    setActiveStatus(parseInt(name, 10));
    setSearchParams({
      type:
        parseInt(name, 10) === 1
          ? ""
          : parseInt(name, 10) === 3
            ? "archive"
            : "closed",
    });
    ChangeFlag();
  };
  return (
    <div style={{ height: "100vh", overflow: "scroll" }}>
      <br></br>


      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
          gap: "10px",
        }}
        className="searchbox"
      >
        <Button onClick={()=>setOpenDrawer(!opendrawer)}>Filter</Button>
        <Search
          placeholder="input search text"
          allowClear
          autoFocus
          autoSize
          enterButton="Search"
          onSearch={onSearch}
          onChange={(e) => {
            if ((e.target.value = "")) {
              setFlag((flag) => !flag);
              setFilterOpen(false);
            }
          }}
          style={{
            maxWidth: 400,
          }}
        />
         {opendrawer &&(
         <JobFilters
         form={form}
         onFinish={onFinish}
         skillOptions={AllSkills}
         jobProfiles={AllProfiles}
         onReset={() => {
           form.resetFields();
           setFlag(flag => !flag);
           setFilterOpen(false);
         }}
         onClose={onClose}
         componentSize={componentSize}
         filterState={opendrawer}
         setFilterState={setOpenDrawer}
       />
      )}
      </div>

     

      {
        zyvkaState.isPending === true ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
            }}
          >
            <Spin size="large" />
          </div>
        ) : (
          <>
            {/* Hot Jobs Section         */}
            {filterOpen === false ? (
              <>
                {" "}
                <Divider orientation="left">
                  <Title level={3}>
                    <FireOutlined style={{ color: "orange" }} /> Hot Jobs
                  </Title>
                </Divider>
                <div className="job-carousel" id="1" style={{ display: "flex", justifyItems: "center", position: "relative" }}>
                  <Button style={{
                    position: "sticky",
                    top: '50%',
                    zIndex: 10,
                    left: 0,
                    width: "40px",
                    paddingLeft: "5px",
                    borderColor: "#6855f6",
                    boxShadow: "1px 2px 9px #B3A8FF"
                  }} onClick={() => {
                    document.getElementById('1').scrollLeft -= 400;
                  }}
                    // type="primary"
                    icon={<LeftOutlined style={{
                      color: "#6855f6", width: "40px",
                    }} />}
                  />
                  {HotJobsData.map((data) => {
                    return (
                      <div>
                        {/* // <div style={{minHeight:"350px" ,minWidth:"270px", marginLeft:"10px", marginRight:"10px"}} key={data.id}> */}
                        <ViewJobDetails
                          data={data}
                          width={370}
                          ChangeFlag={ChangeFlag}
                          skills={skillOption}
                          openToInternalTeamOnly={true}
                        />
                      </div>
                    );
                  })}
                  <Button style={{
                    position: "sticky",
                    width: "40px",
                    paddingLeft: "5px",
                    right: 20,
                    top: '50%',
                    zIndex: 10,
                    borderColor: "#6855f6",
                    boxShadow: "1px 2px 9px #B3A8FF"
                  }} onClick={() => {
                    document.getElementById('1').scrollLeft += 400;
                  }}
                    icon={<RightOutlined style={{
                      color: "#6855f6", width: "40px",
                    }} />}
                  />
                </div>
                <Divider orientation="left">
                  <Title level={3}>
                    <LaptopOutlined style={{ color: "green" }} /> Tech Jobs
                  </Title>
                </Divider>
                <div className="job-carousel" id="2" style={{ display: "flex", justifyItems: "center", position: "relative" }}>
                  <Button style={{
                    position: "sticky",
                    top: '50%',
                    zIndex: 10,
                    left: 0,
                    width: "40px",
                    paddingLeft: "5px",
                    borderColor: "#6855f6",
                    boxShadow: "1px 2px 9px #B3A8FF"
                  }} onClick={() => {
                    document.getElementById('2').scrollLeft -= 400;
                  }}
                    // type="primary"
                    icon={<LeftOutlined style={{
                      color: "#6855f6", width: "40px",
                    }} />}
                  />
                  {TechJobsData.map((data) => {
                    return (
                      <div>
                        {/* // <div style={{minHeight:"350px" ,minWidth:"270px", marginLeft:"10px", marginRight:"10px"}} key={data.id}> */}
                        <ViewJobDetails
                          data={data}
                          width={370}
                          ChangeFlag={ChangeFlag}
                          skills={skillOption}
                          openToInternalTeamOnly={true}
                        />
                      </div>
                    );
                  })}
                  <Button style={{
                    position: "sticky",
                    width: "40px",
                    paddingLeft: "5px",
                    right: 20,
                    top: '50%',
                    zIndex: 10,
                    borderColor: "#6855f6",
                    boxShadow: "1px 2px 9px #B3A8FF"
                  }} onClick={() => {
                    document.getElementById('2').scrollLeft += 400;
                  }}
                    icon={<RightOutlined style={{
                      color: "#6855f6", width: "40px",
                    }} />}
                  />
                </div>
                <Divider orientation="left">
                  <Title level={3}>
                    <BarChartOutlined style={{ color: "blue" }} /> Non-Tech Jobs
                  </Title>
                </Divider>
                <div className="job-carousel" id="3" style={{ display: "flex", justifyItems: "center", position: "relative" }}>
                  <Button style={{
                    position: "sticky",
                    top: '50%',
                    zIndex: 10,
                    left: 0,
                    width: "40px",
                    paddingLeft: "5px",
                    borderColor: "#6855f6",
                    boxShadow: "1px 2px 9px #B3A8FF"
                  }} onClick={() => {
                    document.getElementById('3').scrollLeft -= 400;
                  }}
                    // type="primary"
                    icon={<LeftOutlined style={{
                      color: "#6855f6", width: "40px",
                    }} />}
                  />
                  {NonTechJobsData.map((data) => {
                    return (
                      <div>
                        {/* // <div style={{minHeight:"350px" ,minWidth:"270px", marginLeft:"10px", marginRight:"10px"}} key={data.id}> */}
                        <ViewJobDetails
                          data={data}
                          width={350}
                          ChangeFlag={ChangeFlag}
                          skills={skillOption}
                          openToInternalTeamOnly={true}
                        />
                      </div>
                    );
                  })}
                  <Button style={{
                    position: "sticky",
                    width: "40px",
                    paddingLeft: "5px",
                    right: 20,
                    top: '50%',
                    zIndex: 10,
                    borderColor: "#6855f6",
                    boxShadow: "1px 2px 9px #B3A8FF"
                  }} onClick={() => {
                    document.getElementById('3').scrollLeft += 400;
                  }}
                    icon={<RightOutlined style={{
                      color: "#6855f6", width: "40px",
                    }} />}
                  />
                </div>
              </>
            ) : (
              <Row>
                <Col flex={1.5}></Col>
                <Col flex={7}>
                  <List
                    grid={{
                      gutter: 16,
                      xs: 1,
                      sm: 1,
                      md: 2,
                      lg: 3,
                      xl: 3,
                      xxl: 3,
                    }}
                    dataSource={JobData}
                    renderItem={(item) => (
                      <List.Item>
                        <ViewJobDetails
                          data={item}
                          ChangeFlag={ChangeFlag}
                          skills={skillOption}
                          openToInternalTeamOnly={true}
                        />
                        {/* <JobCard data={item} /> */}

                        {/* <Card hoverable bordered={false} title={item.title} style={{borderRadius:"5px"}}>{item.description}</Card> */}
                      </List.Item>
                    )}
                  />
                </Col>
                <Col flex={1.5}></Col>
              </Row>
            )}

            {/* <Button onClick={()=>{
            document.getElementsByClassName('job-carousel').scrollLeft -= 100;
            }}>Right</Button> */}

            {/* All Jobs section */}
          </>
        )
      }
    </div >
  );
};

export default InternalJobs;
