import { createContext, useState } from 'react';
import SceytChatClient from "sceyt-chat";

export const SceytContext = createContext({
    client: undefined,
    theme: undefined,
    activeChannel: undefined,
    users: [],
    setActiveChannelId: () => {},
});

export const SceytProvider = ({ children }) => {
    const [activeChannel, setActiveChannel] = useState(undefined);
    const [client, setClient] = useState(undefined);
    const [theme, setTheme] = useState('light');
    const [users, setUsers] = useState([]);

    const contextValue = {
        client,
        setClient,
        theme,
        setTheme,
        activeChannel,
        setActiveChannel,
        users,
        setUsers,
    };

    return (
        <SceytContext.Provider value={contextValue}>
            {children}
        </SceytContext.Provider>
    );
};