import React from 'react';
import { Form, Select, Button, DatePicker, Input, InputNumber } from 'antd';
const { Option } = Select;

const JobFilters = ({
  form,
  onFinish,
  skillOptions,
  jobProfiles,
  onReset,
  onClose,
  filterState,
  setFilterState,
  componentSize = "default"
}) => {  
  const statusMenu = [
    { label: "Open", value: "open" },
    { label: "Candidate Offered", value: "candidate offered" },
    { label: "Candidate Accepted", value: "candidate accepted" },
    { label: "Candidate Joined", value: "candidate joined" },
    { label: "On Hold", value: "on hold" },
    { label: "No More Submissions", value: "no more submissions" },
    { label: "Slot Pending", value: "slot pending" },
    { label: "Requirement Changed", value: "requirement changed" },
  ];

  const jobTypeOptions = [
    { label: "Tech", value: "Tech" },
    { label: "Non-Tech", value: "Non-Tech" },
  ];

  const fieldStyle = {
    width: "100%",
    minWidth: "200px",
    height: "32px"
  };

  const rowStyle = {
    display: "flex",
    flexWrap: "wrap",
    gap: "10px"
  };

  const formItemStyle = {
    flex: 1,
    minWidth: "200px"
  };

  const buttonContainerStyle = {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    gap: "10px",
    marginRight: "10px",
    marginTop: "10px"
  };

  const buttonStyle = {
    minWidth: "12%",
    maxWidth: "200px"
  };

  return (
    <div style={{ width: "100%", padding: "16px" }}>
      <Form
        labelCol={{ span: 4 }}
        layout="horizontal"
        onFinish={onFinish}
        form={form}
        size={componentSize}
      >
        <div style={rowStyle}>
          <Form.Item name="job_title" style={formItemStyle}>
            <Select showSearch placeholder="Profile" style={fieldStyle}>
              {jobProfiles.map((data, idx) => (
                <Option key={idx} value={data.profile}>{data.profile}</Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item name="min_experience_req" style={formItemStyle}>
              <InputNumber placeholder="Min experience (in years)" style={fieldStyle} min={0} />
          </Form.Item>

          <Form.Item name="max_experience_req" style={formItemStyle}>
            <InputNumber placeholder="Max experience (in years)" style={fieldStyle} min={0} />
          </Form.Item>

          <Form.Item name="min_ctc" style={formItemStyle}>
            <InputNumber placeholder="Min CTC (in lakhs)" style={fieldStyle} min={0} />
          </Form.Item>
        </div>

        <div style={rowStyle}>
          <Form.Item name="max_ctc" style={formItemStyle}>
            <InputNumber placeholder="Max CTC (in lakhs)" style={fieldStyle} min={0} />
          </Form.Item>

          <Form.Item name="min_notice_period" style={formItemStyle}>
              <InputNumber placeholder="Min Notice Period (in days)" style={fieldStyle} min={0} />
          </Form.Item>

          <Form.Item name="max_notice_period" style={formItemStyle}>
            <InputNumber placeholder="Max Notice Period (in days)" style={fieldStyle} min={0} />
          </Form.Item>

          <Form.Item name="location" style={formItemStyle}>
            <Input placeholder="Location" style={fieldStyle} />
          </Form.Item>
        </div>

        <div style={rowStyle}>
          <Form.Item name="skills_req" style={formItemStyle}>
            <Select showSearch mode="multiple" placeholder="Skills" style={fieldStyle}>
              {skillOptions.map((data, idx) => (
                <Option key={idx} value={data.skill}>{data.skill}</Option>
              ))}
            </Select>
          </Form.Item>

          {/* <Form.Item name="prefered_industry" style={formItemStyle}>
          <Input placeholder="prefered Industory" style={fieldStyle} />
          </Form.Item> */}

          <Form.Item name="status" style={formItemStyle}>
            <Select placeholder="Status" style={fieldStyle} options={statusMenu} />
          </Form.Item>

          <Form.Item name="jobType" style={formItemStyle}>
            <Select placeholder="Job Type" style={fieldStyle} options={jobTypeOptions} />
          </Form.Item>
          <Form.Item name="hotJob" style={formItemStyle}>
            <Select placeholder="Hot Job" style={fieldStyle}>
              <Option value="yes">Yes</Option>
              <Option value="no">No</Option>
            </Select>
          </Form.Item>
        </div>

        {/* <div style={rowStyle}>
          <Form.Item name="active_status" style={formItemStyle}>
            <Select placeholder="Active Status" style={fieldStyle}>
            {status.map((data) => (
                <Option key={data} value={data}>{data}</Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item name="myjobs" style={formItemStyle}>
            <Select placeholder="My Jobs" style={fieldStyle}>
              <Option value="yes">Yes</Option>
              <Option value="no">No</Option>
            </Select>
          </Form.Item>
        </div> */}

        <div style={buttonContainerStyle}>
          <Button type="primary" htmlType="submit" style={buttonStyle}>
            Filter
          </Button>
          <Button style={buttonStyle} onClick={()=>{
            onReset()
            form.resetFields()
          }
            }>
            Reset All Filters
          </Button>
          <Button style={buttonStyle} onClick={onClose}>
            Hide Filters
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default JobFilters;